import { Component } from 'react';

import Auth from 'services/utils/Auth';

class NoCheckIn extends Component {

  componentDidMount() {
    Auth.deauthenticateUser();
    const { branchUrl } = this.props.match.params;
   	window.location.href =  `/${branchUrl}?checkin=no`;
  }

  render() { return null; }
}

export default NoCheckIn;
