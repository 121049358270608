import { SET_DATA, SWITCH_SHOW} from "../../types/MenuTypes";

export const surchargesData = (data) => {
  return {
    type : SET_DATA,
    payload : data
  }
}

export const switchShow = (data) => {
  return {
    type : SWITCH_SHOW,
    payload : data
  }
}

