import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import getUserLocale from 'get-user-locale';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

let language = getUserLocale();
if (language) { language = language.substr(0, 2); }
const listOfLanguages = ['en', 'ja'];
const browserLanguage = listOfLanguages.includes(language) ? language : 'en';

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: browserLanguage,
    backend: {
      /* translation file path */
      //loadPath: '/locales/{{lng}}/{{ns}}.json' //'/locales/{{lng}}.json',
      loadPath: '/locales/{{lng}}.json'
    },
    fallbackLng: 'en',
    debug: false,// process.env.NODE_ENV === "1development",
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    // ns: ['translations'],
    defaultNS: 'translations',
    // keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true
    },
  });


export default i18n;