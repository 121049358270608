import React from 'react';
import Settings from 'services/config/Settings';
import { withWindowSizeListener } from 'react-window-size-listener';
import fetchJSON from 'services/utils/fetchJSON';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { surchargesToString } from '../../helpers/surchargesToString';
import {correctModifierSurcharges} from "../../helpers/correctModifierSurcharges";

class SingleOrderReceipt extends React.Component {
  state = {
    order: {
      id: '',
      order_items: [],
      transaction: {}
    },
    isFetching: true,
    tables: [],
    branch: {},
    activeReadyPayModal: false
  }
	componentDidMount = () => {
		document.title = `MyOrders | ${Settings.title}`;
    this.getOrder();
  }

  calculateTotal = (total, fee) => {
    return (total + fee).toFixed(2)
  }

  getOrder = () => {
    const { orderUuid } = this.props.match.params;
    this.setIsFetching(true);
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/checkout/getOrder/', {
      method: 'POST',
      body: {
        orderUuid
      }
    }).then(response => {
      this.setIsFetching(false);
      if (response.code === 200) {
        const { order, timezone } = response.data;

        order.submitted_at = order.submitted_at ? moment.utc(order.submitted_at).tz(timezone).format('MM/DD/YY h:mm a') : false;
        this.setState({
          orderTotal: response.data.order.total,
          branchUuid: response.data.branchUuid,
          branchName: response.data.branchName,
          is_paid: response.data.order.is_paid,
          order: response.data.order,
          tables: response.data.tables,
          branch: response.data.branch,
          surcharges: response.data.order.surcharges,
        }, this.proccessOrder);
        console.log(response.data.order);
      }
     }).catch(error => {
        console.log(error);
     });
  }
  renderStatus = () => {
    const { order } = this.state;
    const render = [];
    const { order_type } = order;
    console.log('order', order);

    if (!order.is_refunded && !order.is_canceled) {
      if (order.is_paid) {
        render.push(<div className="checkoutPaidCardStatus__tag paid" key="paid">Paid</div>)
      } else {
        render.push(<div className="checkoutPaidCardStatus__tag notpaid" key="notpaid">Not Paid</div>)
      }
    }

    if (order.is_refunded) {
      render.push(<div className="checkoutPaidCardStatus__tag notpaid" key="Refunded">Refunded</div>)
    }

    if (order.is_canceled) {
      render.push(<div className="checkoutPaidCardStatus__tag notpaid" key="is_canceled">Canceled</div>)
    }

    if (order.is_delivered && order_type === 'dine_in') {
      render.push(<div className="checkoutPaidCardStatus__tag delivered" key="delivered">Delivered</div>)
    }


    return render;
  }
  setIsFetching = (param) => {
    this.setState({
      isFetching: param
    })
  }
  closeModal = () => {
    this.setState({
      activeReadyPayModal: false
    });
  }
  renderPrice = (order_item, s) => {
    let totalModifiers = 0;
    if (order_item.modifiers && order_item.modifiers.length) {
      order_item.modifiers.forEach((modifier) => {
        if (modifier.price) totalModifiers += correctModifierSurcharges(parseFloat(modifier.price), s);
      })
    }
    const gross = correctModifierSurcharges(order_item.quantity * order_item.price, s) + totalModifiers * order_item.quantity;

    return gross.toFixed(2)
  }
  render() {
    const {
      order,
      isFetching,
      surcharges,
    } = this.state;

  	const containerStyle = {
	    width: this.props.windowSize.windowWidth,
	    height: this.props.windowSize.windowHeight,
	    minHeight: this.props.windowSize.windowHeight
	  };
    const { branchUrl } = this.props.match.params;

    return (
      <div className="singleOrder" style={containerStyle}>
      	{ !isFetching &&  <div className="checkoutPaid">
          <div className="checkoutPaidCard">
            <div className="checkoutPaidBox">
              <div className="menuTop" style={{ minHeight: '1px', paddingTop: '15px', paddingBottom: '20px' }}>
                <div className="menuTop__name">Receipt #{order.index_id}</div>
              </div>

              <div className="checkoutPaidCard__list">
                {order.order_items.map((item, i) =>
                  <div key={i} className="checkoutPaidCard__item">
                    <div className="checkoutPaidCard__itemFirst">
                      <div>{item.product.display_name}</div>
                      <div style={{color: '#868686', marginTop: '5px', fontSize: '12px'}}>{item.comments}</div>
                    </div>
                    <div className="checkoutPaidCard__itemMiddle">{item.quantity}</div>
                    {surcharges === 1 ?
                      <div className="checkoutPaidCard__itemLast">{this.renderPrice(item, 1)}</div>
                      :
                      <>
                      <div className="checkoutPaidCard__itemLast" style={{textDecoration:"line-through"}}>{this.renderPrice(item, 1)}</div>
                      <div className="checkoutPaidCard__itemLast">{this.renderPrice(item, surcharges)}</div>
                      </>
                    }
                  </div>
                )}
              </div>
              <div className="checkoutPaidCard__footer">
                <div className="checkoutPaidCard__footerItem">
                  GST
                  <span className="checkoutPaidCard__footerPrice">${!!order.vat_amount ? order.vat_amount.toFixed(2) : ''}</span>
                </div>
                {surcharges != 1 ?
                  <div className="checkoutPaidCard__footerItem">
                    {surcharges > 1 ? `Surcharges` : `Discount` }
                    <span className="checkoutPaidCard__footerPrice">{surchargesToString(surcharges)}</span>
                  </div>
                  : null }
                { order.processing_fee !== 0 ?
                  <div className="checkoutPaidCard__footerItem">
                    Processing fee
                    <span className="checkoutPaidCard__footerPrice">${!!order.processing_fee?order.processing_fee.toFixed(2):''}</span>
                  </div>
                  : null}
                <div className="checkoutPaidCard__footerItem">
                  Total
                  <span className="checkoutPaidCard__footerPrice checkoutPaidCard__footerPrice--high">${this.calculateTotal(order.total, order.processing_fee)}{order.payment_processing_by_client == 1 && order.transaction.method_display === 'card' ? `*` : null}</span>
                </div>
                { order.submitted_at ? <div className="checkoutPaidCard__footerItem">
                  Date
                  <span className="checkoutPaidCard__footerPrice">{order.submitted_at}</span>
                </div> : null }

                {order.transaction && <div className="checkoutPaidCard__footerItem">
                  Paid By
                  <span className="checkoutPaidCard__footerPrice">{order.transaction.method_display}</span>
                </div> }
                {order.payment_processing_by_client == 1 && order.transaction.method_display === 'card' ?
                  <div className="checkoutPaidCard__footerItem">
                    <span className="checkoutPaidCard__footerPrice">*The payment processing fee was applied</span>
                  </div>
                  : null}
              </div>
            </div>
            <div style={{ margin: '20px' }}>
            <Link
              className="appBody__bottomBtn"
              style={{display: 'block' }}
              to={`/${branchUrl}/orders`}>My orders</Link>
            </div>
          </div>
         </div> }
         {isFetching && <div className="singleOrder__loading">Loading...</div>}
      </div>
    )
  }
};

export default withWindowSizeListener(SingleOrderReceipt);
