import React from 'react';

const MenuTop = (branchName, logo, banner, title_in_app) => {
	if (!branchName) return null;

	return <div className={"menuTop "+ (banner ? 'menuTop--withBanner' : '')} style={{ backgroundImage: banner && `url(${banner})`}}>
      { logo && <div className="menuTop__logoWrap">
        	<img src={logo} className="menuTop__logo" alt="Branch logo"  />
        </div> }
      { branchName && title_in_app && <div className="menuTop__name">{branchName}</div> }
    </div>
}

export default MenuTop;