import React from 'react';
import { Link } from 'react-router-dom';
import Auth from 'services/utils/Auth';

import './styles.less';
import SlideDrawer from '../SlideDrawer'
import { ReactComponent as IconChevronRight } from '../images/chevron-right.svg';


class SlideMenu extends React.Component {
	/*state = {
		appLanguage: 'en',
		showLanguageMenu: false
	}
	componentDidMount = () => {
		const appLanguage = Cookies.get('app_language') || 'en';
		this.setState({
			appLanguage
		});
	}*/
	renderMenu = () => {
		const { branchUrl } = this.props.match.params;
		const menuDropdown = [
			{
				title: 'My orders',
				type: 'link',
				value: `/${branchUrl}/orders`
			},
			{
				title: 'Help',
				type: 'link',
				value: `/${branchUrl}/faq`
			},
			// {
			// 	title: 'About',
			// 	type: 'link',
			// 	value: '/about'
			// },
			{
				title: 'Logout',
				type: 'link',
				value: `/${branchUrl}/logout`
			}
		]
		return menuDropdown.map((obj, index) => {
			if (obj.type === 'link') {
				return (
					<Link className="slideMenu__menuItem" to={obj.value} key={index} onClick={this.props.closeMenu}>
						{obj.title}
						<IconChevronRight className="slideMenu__menuItem--icon" />
					</Link>
				)
			} else {
				return (
					<span className="slideMenu__menuItem" key={index} onClick={obj.value}>
					{obj.title}
					<IconChevronRight className="slideMenu__menuItem--icon" />
					</span>
				)
			}
		})
	}
	renderPlainMenu = () => {
		const { branchUrl } = this.props.match.params;
		const menuDropdown = [
			{
				title: 'Help',
				type: 'link',
				value: `/${branchUrl}/faq`
			},
			{
				title: 'About',
				type: 'link',
				value: `/${branchUrl}/logout`
			}
		]
		return menuDropdown.map((obj, index) => {
			if (obj.type === 'link') {
				return (
					<Link className="slideMenu__menuItem" to={obj.value} key={index}>
						{obj.title}
						<IconChevronRight className="slideMenu__menuItem--icon" />
					</Link>
				)
			} else {
				return (
					<span className="slideMenu__menuItem" key={index} onClick={obj.value}>
					{obj.title}
					<IconChevronRight className="slideMenu__menuItem--icon" />
					</span>
				)
			}
		})
	}
  render() {
  	const isUserAuthenticated = Auth.isUserAuthenticated();
    return (
			<SlideDrawer isOpen={this.props.isOpen} handleClose={this.props.handleClose}>
				{ isUserAuthenticated ? this.renderMenu() : this.renderPlainMenu() }
				<div className="slideMenu__footer">
					<a href="mailto:support@dezy.xyz" target="_blank" rel="noopener noreferrer" className="slideMenu__smallLink">support@dezy.xyz</a>
				</div>
			</SlideDrawer>
    )
  }
};


export default SlideMenu;
