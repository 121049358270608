import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { withWindowSizeListener } from 'react-window-size-listener';
import Settings from 'services/config/Settings';
// import Auth from 'services/utils/Auth';
// import { getFingerprint } from 'services/utils/Analytics';
import fetchJSON from 'services/utils/fetchJSON';
import { useTranslation, Trans } from 'react-i18next';
// import { Link } from 'react-router-dom';
import { Formik, FieldArray } from 'formik';
import { ReactComponent as DezyHeaderLogo } from 'components/images/dezy-header-logo-v2.svg';

import Button from 'components/Button/Button';
import qs from 'qs';
import Cookies from 'js-cookie';

// import PhoneInputField from 'components/PhoneInputField/PhoneInputField';


export class Dependent {
  constructor() {
    this.name = "";
  }
}

const CheckIn = (props) => {
  // Then inside the component body
  const { t } = useTranslation();

  const [branchUuid, setBranchUuid] = useState();
  const [tableId, setTableId] = useState();
  const [defaultValues, setDataFromServer] = useState({
    email: '',
    phone: '',
    full_name: ''
  });
  const { branchUrl } = props.match.params;

  const componentDidMount = () => {
    document.title = `${t('checkinPage.documentTitle', { defaultValue: 'Check-In' })} | ${Settings.title}`;
    // console.log('branchUrl', branchUrl, 'props', props);

    getCovidData();

    if (window.location.search) {
      console.log('window.location', window.location.pathname);
      const param = window.location.search;
      const paramFromUrl = qs.parse(param, { ignoreQueryPrefix: true });
      const branch = paramFromUrl.branch ? paramFromUrl.branch : null;

      setBranchUuid(branch);

      const table = paramFromUrl.table ? paramFromUrl.table : null;
      setTableId(table);

      if (branch && Cookies.get(`covid_${branchUrl}`) !== undefined) {
        let linkToMenu = `/${branchUrl}/`;
        if (table) {
          linkToMenu = `${linkToMenu}?table=${table}`;
        }
        props.history.push(linkToMenu);
      }
    }

  }
  useEffect(componentDidMount, []);

  const getCovidData = () => {
    console.log('getCovidData');

    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/auth/covid/${branchUrl}`, {
      method: 'GET'
    }).then(response => {
      console.log(response);
      const uuid = _.get(response, 'branchUuid')
      if (uuid) {
        setBranchUuid(uuid);
      }

      if (response.code === 200) {
        if (response.data) {
          setDataFromServer({
            email: response.data.email,
            phone: response.data.phone,
            full_name: response.data.name
          });
        }
      }

    }).catch(error => {
      console.log(error);
    });
  };

  // const preventDefault = (e) => {
  //   e.preventDefault();
  // }
  // // remove scrolling jumping feature on iphones
  // useEffect(() => {
  //   document.body.addEventListener('touchmove', preventDefault, { passive: false });
  //   // returned function will be called on component unmount
  //   return () => {
  //     document.body.removeEventListener('touchmove', preventDefault)
  //   }
  // }, []);

  const containerStyle = {
    width: props.windowSize.windowWidth,
    height: props.windowSize.windowHeight,
    minHeight: props.windowSize.windowHeight
  };
  const getData = () => {

    return  {
      email: defaultValues.email,
      phone: defaultValues.phone,
      full_name: defaultValues.full_name,
      departure: '',
      dependents: []
    };
  }

  const validate = values => {
    const errors = {};
    const requiredText = t('shareable.required', { defaultValue: 'Required' });

    if (!values.email) {
      errors.email = requiredText;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = t('shareable.invalidEmail', { defaultValue: 'Invalid email address' });
    }

    if (!values.full_name) {
      errors.full_name = requiredText;
    }
    if (!values.phone) {
      errors.phone = requiredText;
    }
    if (!values.departure) {
      errors.departure = requiredText;
    }
    if (values.dependents && values.dependents.length) {
      values.dependents.forEach((dependent, index) => {
        if (!dependent.name) {
          if (!errors.dependents) {
            errors.dependents = [];
          }
          if (errors.dependents.indexOf(index) === -1) {
            errors.dependents[index] = {};
          }
          errors.dependents[index].name = requiredText;
        }
      });
    }

    return errors;
  };
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    // alert(JSON.stringify(values, null, 2));

    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/auth/covid/', {
      method: 'POST',
      body: {
        branchUuid,
        ...values
      }
    })
    .then(response => {
      console.log(response);
      if (response.code === 200) {
        // alert('You successfully checked in. Please enjoy our digital menu!');
        setSubmitting(false);
        resetForm();

        if (branchUuid) {
          const { departure } = values;
          const departureDeadline = new Date(new Date().getTime() + departure * 60 * 1000);
          Cookies.set(`covid_${branchUrl}`, 1, { expires: departureDeadline });
          let linkToMenu = `/${branchUrl}/`;
          if (tableId) {
            linkToMenu = `${linkToMenu}?table=${tableId}`;
          }

          props.history.push(linkToMenu);
        }
      }
    })
    .catch(error => {
      setSubmitting(false);
      console.log(error);
    });
  };


  return (
    <Formik
      initialValues={getData()}
      enableReinitialize={true}
      validate={validate}
      onSubmit={onSubmit}
      >
      {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, handleBlur }) => {
        const errorField = (fieldKey) => {
          return errors[fieldKey] && touched[fieldKey] && <small className="fillInForm__itemError">{errors[fieldKey]}</small>
        }
        const formItemClass = (fieldKey = null, itsError = false) => {
          return `fillInForm__item ${ (errors[fieldKey] && touched[fieldKey]) || itsError ? 'withError' : ''}`;
        }
        return (
          <form onSubmit={handleSubmit} className="app-container" style={containerStyle}>
            <div className="appBody__top">
              <div className="appBody__container">
                <div className="appBody__wrapper">
                  <div className="fillIn">
                    <div className="fillIn__logo">
                    <DezyHeaderLogo alt="dezy logo" className="fillIn__logoImg" />
                    </div>
                    <div className="fillInForm">
                      <div className={formItemClass('full_name')}>
                        <label htmlFor="full_name"><Trans i18nKey="shareable.fullName">Full name</Trans></label>
                        <input
                          className="fillInForm__itemInput"
                          type="text"
                          name="full_name"
                          placeholder={t('shareable.fullName', { defaultValue: 'Full name' })}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.full_name}
                        />
                        { errorField('full_name') }
                      </div>


                      <div className={formItemClass('email')}>
                        <label htmlFor="email">{t('shareable.email', { defaultValue: 'Email' })}</label>
                        <input
                          className="fillInForm__itemInput"
                          type="email"
                          name="email"
                          placeholder={t('shareable.email', { defaultValue: 'Email' })}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        { errorField('email') }
                      </div>

                      <div className={formItemClass('phone')}>
                        <label htmlFor="phone">{t('shareable.phoneNumber', { defaultValue: 'Phone number' })}</label>
                        <input
                          className="fillInForm__itemInput"
                          type="tel"
                          name="phone"
                          placeholder={t('shareable.phoneNumber', { defaultValue: 'Phone number' })}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                        />
                        { errorField('phone') }
                      </div>

                      <div className={formItemClass('departure')}>
                        <label htmlFor="departure">{t('shareable.departureTime', { defaultValue: 'Expected departure time' })}</label>
                        <select
                          className="fillInForm__itemSelect"
                          type="text"
                          name="departure"
                          // placeholder={t('shareable.departureTime', { defaultValue: 'Expected departure time' })}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.departure}
                        >
                          {!values.departure ? <option>{t('shareable.departureTime', { defaultValue: 'Expected departure time' })}</option> : null }
                          <option value="30">{t('checkinPage.departureTime.30', { defaultValue:  '30 minutes or less' })}</option>
                          <option value="60">{t('checkinPage.departureTime.60', { defaultValue:  '60 minutes' })}</option>
                          <option value="90">{t('checkinPage.departureTime.90', { defaultValue:  '90 minutes' })}</option>
                          <option value="120">{t('checkinPage.departureTime.120', { defaultValue:  '120 minutes' })}</option>
                        </select>
                        { errorField('departure') }
                      </div>
                      <FieldArray
                        name="dependents"
                        render={({ insert, remove, push }) => (
                          <div className={formItemClass()}>
                            {values.dependents.length > 0 &&
                              <React.Fragment>
                                <div className="fillIn__dependentTitle" >Dependent(s)</div>
                                { values.dependents.map((dependent, index) => (
                                  <div className={formItemClass(`dependents[${index}].name`, errors &&
                                      errors.dependents &&
                                      errors.dependents[index] &&
                                      errors.dependents[index].name &&
                                      (touched &&
                                        touched.dependents &&
                                        touched.dependents[index] &&
                                        touched.dependents[index].name))} key={index}>
                                    <label htmlFor={`dependents.${index}.name`}>
                                      {t('checkinPage.dependent.one', { defaultValue: 'Dependent' })}
                                    </label>
                                    <div className="fillIn__dependentDeleteBox">
                                      <input
                                        className="fillInForm__itemInput"
                                        type="text"
                                        name={`dependents.${index}.name`}
                                        placeholder={t('checkinPage.dependent.fullName', { defaultValue: 'Dependent full name' })}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.dependents[index].name}
                                      />
                                      <button
                                        type="button"
                                        className="fillIn__dependentDeleteButton"
                                        onClick={() => remove(index)}
                                      >
                                        ×
                                      </button>
                                    </div>
                                    {errors &&
                                      errors.dependents &&
                                      errors.dependents[index] &&
                                      errors.dependents[index].name &&
                                      (touched &&
                                        touched.dependents &&
                                        touched.dependents[index] &&
                                        touched.dependents[index].name) && (
                                        <small className="fillInForm__itemError">{errors.dependents[index].name}</small>
                                      )}
                                  </div>
                                ))}
                              </React.Fragment>
                            }
                            <div className={formItemClass()}>
                              <button className="fillIn__dependentAdd" type="button" onClick={() => push(new Dependent())}>
                                <Trans i18nKey="checkinPage.dependent.add">Add a dependent</Trans>
                              </button>
                            </div>
                          </div>
                        )}
                      />

                    </div>
                    <div className="fillIn__privacy">
                      <p className="fillIn__privacyText">
                        Completing the COVID Checkin is a requirement for entry for any customers drinking or dining at this venue.
                        <br/>
                        Contact information is required from each patron, not a single patron within a group.
                        <br/>
                        Your details will be kept for a minimum of 30 days and a maximum of 56 days to assist with contact tracing. Refusal to complete this task may result in refusal of service.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="appBody__bottom">
              <div className="appBody__bottomContainer">
                <Button type="submit" disabled={isSubmitting}>
                  <Trans i18nKey="checkinPage.checkinButton">Check-in</Trans>
                </Button>
              </div>
            </div>
          </form>
        )
      }}
    </Formik>
  );
};


export default withWindowSizeListener(CheckIn);

