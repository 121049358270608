export const customStyles = (main_color_theme) => {
	const css = `
		.singleProduct__close--icon path,
		.singleProduct__quantityBtn--icon path,
		.slideMenu__icon path,
		.slideMenu__menuItem--icon path,
		.cartScreenItem__quantityBtn--icon path,
		.cartScreenHeader__back--icon path,
		.headerMenu__burger-btn--icon path
		{
			fill: ${main_color_theme}; 
		}
		.checkoutPaidCardStatus__tag {
			border-color: ${main_color_theme};
			color: ${main_color_theme} 
		}
		.menuCategories__link:after,
		.cartFloated
		{ background: ${main_color_theme}}
		.slideMenu__menuItem,
		.myOrders__title,
		.singleProduct__price,
		.myOrdersItem__price,
		.checkoutPaidCard__footerPrice--high,
		.cartScreenHeader__back,
		.cartScreenTotal__price--red,
		.checkoutPaidCard__footerLinks a
		{ color: ${main_color_theme} }
		.cartScreenFooter {
			border-color: ${main_color_theme} 
		}
		.singleProduct__add,
		.myOrdersItem__orderAgain,
		.appBody__bottomBtn,
		.cartScreenFooter__checkout
		{ background: ${main_color_theme}; border-color: ${main_color_theme} }
		.menuTop__name--prapare {
			color: ${main_color_theme};
		}
		.appBody__bottomBtn.inversion-colors 
		{ color: ${main_color_theme}; border-color: ${main_color_theme} }
		`;
		const head = document.head || document.getElementsByTagName('head')[0];
		const style = document.createElement('style');

		head.appendChild(style);

		style.type = 'text/css';
		if (style.styleSheet){
		  // This is required for IE8 and below.
		  style.styleSheet.cssText = css;
		} else {
		  style.appendChild(document.createTextNode(css));
		}
}