import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { withWindowSizeListener } from 'react-window-size-listener';
import Settings from 'services/config/Settings';
import dezyHeaderLogoRed from 'components/images/dezy-header-logo-v2.svg';
import qs from 'qs';
import fetchJSON from 'services/utils/fetchJSON';
// import Auth from 'services/utils/Auth';
import { ReactComponent as IconCloseProduct } from './components/images/icon-close-single-product.svg';
import { ReactComponent as IconProductRemove } from './components/images/icon-products-remove.svg';
import { ReactComponent as IconProductAdd } from './components/images/icon-products-add.svg';
import Cookies from 'js-cookie';
import MenuTop from 'components/MenuTop/MenuTop';
import { Document, Page, pdfjs } from 'react-pdf';
import Button from 'components/Button/Button';
import Modal from 'react-modal';
import { connect } from "react-redux";
// import * as createjs from 'createjs-module';
import showTotalWithSurcharges from '../../helpers/showTotalWithSurcharges'
import { useDispatch, useSelector } from "react-redux";
import rootReducers from '../../redux/reducers/rootReducer'
import './styles.less';
import { surchargesToString } from '../../helpers/surchargesToString';
import formModifierPriceString from "../../helpers/formModifierPriceString";
import { surchargesData, switchShow } from "../../redux/actions/MenuAction/MenuActions";
import rootReducer from "../../redux/reducers/rootReducer";
import surchargesLogoMessageString from '../../helpers/surchargesLogoMessageString'
import { correctModifierSurcharges } from "../../helpers/correctModifierSurcharges";
import SlideDrawer from "../../components/Header/components/SlideDrawer";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const Menu = (props) => {
	const { t } = useTranslation();
	const [tableNumber, setTableNumber] = useState(null);
	const [restaurantName, setRestaurantName] = useState(null);
	const [jsonMenu, setJsonMenu] = useState({});
	const [branchObj, setBranchObj] = useState({});
	const [branchUuid, setBranchUuid] = useState();
	const [menuId, setMenuId] = useState();
	const [isFetching, setIsFetching] = useState(false);
	const [orderUuid, setOrderUuid] = useState(null);
	const { branchUrl } = props.match.params;
	const covid_checkin = props?.branchObj?.covid_checkin
	const [isAlcoholSalesForTakeaway, setIsAlcoholSalesForTakeaway] = useState(false)
	const [confirmAlcoholShow, setConfirmAlcoholShow] = useState(false)
	const dispatch = useDispatch()

	const surcharge_data = useSelector((state: rootReducer) => state.MenuReducer)

	const componentDidMount = () => {
		document.title = `${t('manuPage.documentTitle', { defaultValue: 'Menu' })} | ${Settings.title}`;
		let tableId = null;
		let nocheckin = null;
		if (window.location.search) {
			const param = window.location.search;
			const paramFromUrl = qs.parse(param, { ignoreQueryPrefix: true });
			const table = paramFromUrl.table ? paramFromUrl.table : null;
			tableId = table;
			nocheckin = paramFromUrl.checkin ? paramFromUrl.checkin : null;
		}

		if (nocheckin !== 'no') {
			if (covid_checkin) {
				if (branchUrl && Cookies.get(`covid_${branchUrl}`) === undefined) {
					let linkToCheckin = `/${branchUrl}/checkin`;
					if (tableId) {
						linkToCheckin = `${linkToCheckin}?table=${tableId}`;
					}
					props.history.push(linkToCheckin);
					return;
				}
			}
		}

		getBranchMenu(branchUrl, tableId);

	}
	useEffect(componentDidMount, []);

	const getBranchMenu = (branchUrl, tableId) => {
		fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/branch/menu/${branchUrl}`, {
			method: 'GET'
		}).then(response => {
			console.log(response);
			if (response.code === 200) {
				setBranchObj(response.data.branch);

				setJsonMenu(response.data);
				setMenuId(response.data.menu_id);

				if (response.data.displayName) {
					setRestaurantName(response.data.displayName);
				}
				if (response.data.branchUuid) {
					setBranchUuid(response.data.branchUuid);
				}

				setIsAlcoholSalesForTakeaway(response?.data?.branch?.is_alcohol_sales_for_takeaway || false)

				resumeOrder(response.data, branchUrl, response.data.menu_id, tableId);
			} else {
				window.location.href = '/';
			}
		}).catch(error => {
			console.log(error);
			// window.location.href = '/';
		});
	}

	const [visibleCategory, setVisibleCategory] = useState();
	const [activeItem, setActiveItem] = useState({});
	const [visibleItem, setVisibleItem] = useState(0);
	const [productQuantity, setProductQuantity] = useState(1);
	const [productComments, setProductComments] = useState('');
	const [productModifiers, setProductModifiers] = useState([]);
	const [radioSelectorHandler, setRadioSelectorHandler] = useState({});


	const [cartList, setCartList] = useState([]);
	const [cartVisible, setCartVisible] = useState(0);
	const [surcharges, setSurcharges] = useState(1);
	const [surcharges_amount, setSurchargesAmount] = useState(0);
	const [payment_processing_by_client, setPaymentProcessingByClien] = useState(0);

	const headerOffset = 95;

	const getDimensions = ele => {
		const height = ele.offsetHeight;
		const offsetTop = ele.offsetTop;
		const offsetBottom = offsetTop + height;
		return {
			height,
			offsetTop,
			offsetBottom,
		};
	};

	const [sectionRefs, setSectionRefs] = useState([]);
	let menuCategories = null;

	const handleScroll = (params) => {
		if (!sectionRefs.length) return;

		const scrollPosition = params.target.scrollTop + headerOffset;
		const selected = sectionRefs.find(({ category_id, ref }) => {
			const ele = ref;
			if (ele) {
				const { offsetBottom, offsetTop } = getDimensions(ele);
				return scrollPosition > offsetTop && scrollPosition < offsetBottom;
			}
			return null;
		});
		if (selected && selected.category_id !== visibleCategory) {
			const menuItem = selected.menu;
			if (!menuCategories) {
				menuCategories = document.getElementById("menuCategories");
			}
			menuCategories.scrollLeft = menuItem.offsetLeft - 20;

			setVisibleCategory(selected.category_id);
		}
		return null;
	};

	const initSmartScroll = () => {
		let firstCategory = false;
		if (jsonMenu.constructor === Object && Object.entries(jsonMenu).length === 0) return;
		jsonMenu.categories.forEach((category, index) => {
			if (!firstCategory) {
				setVisibleCategory(category.id);
				firstCategory = true;
			}
			sectionRefs.push({
				category_id: category.id,
				ref: document.querySelector(`[id="section_category_${category.id}"]`),
				menu: document.querySelector(`[id="menu_category_${category.id}"]`)
			});
		});
		setSectionRefs(sectionRefs);
	}

	const banner = branchObj?.banner
	const logo = branchObj?.logo

	useEffect(() => {
		if (jsonMenu.constructor === Object && Object.entries(jsonMenu).length !== 0) {
			setTimeout(() => {
				initSmartScroll();
			}, 1500)
			// const preloadContent = new createjs.LoadQueue(true);

			// const imagesList = [];
			// const tmp = [];
			// if (banner) {
			// 	imagesList.push(new createjs.LoadItem().set({
			// 		id: 'image',
			// 		src: banner,
			// 	}))
			// }
			// if (logo) {
			// 	imagesList.push(new createjs.LoadItem().set({
			// 		id: 'image',
			// 		src: logo,
			// 	}))
			// }
			// jsonMenu.categories.forEach((category, index) => {
			// 	category.items.forEach((item, index) => {
			// 		if (item.thumbnail && tmp.indexOf(item.thumbnail) === -1) {
			// 			console.log('createjs.LoadItem', createjs.LoadItem);
			// 			const loadItem = new createjs.LoadItem().set({
			// 				id: 'image',
			// 				src: item.thumbnail,
			// 			});
			// 			imagesList.push(loadItem);
			// 			tmp.push(item.thumbnail)
			// 		}

			// 	})
			// })

			// console.log('imagesList', imagesList);
			// imagesList.forEach((image) => {
			//    preloadContent.loadFile(image);
			//  });

		}
	}, [jsonMenu]);  // eslint-disable-line

	useEffect(() => {
		initSmartScroll();
		const app = document.getElementById("appContainer");

		if (app) {
			app.addEventListener("scroll", handleScroll);
			return () => {
				app.removeEventListener("scroll", handleScroll);
			};
		}
	}, []);  // eslint-disable-line

	const scrollTo = (category_id) => {
		const element = document.getElementById(`section_category_${category_id}`);
		const app = document.getElementById("appContainer");
		if (element && app) {
			const elementPosition = element.offsetTop;
			const offsetPosition = elementPosition - headerOffset + 2;

			app.scrollTo({
				top: offsetPosition,
				behavior: "smooth"
			});
		}
	};

	const resumeOrder = (menu, branchId, menuId, tableId) => {
		setIsFetching(true);
		fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/orders/resume/', {
			method: 'POST',
			body: {
				branchUuid: branchId,
				menuId,
				tableId
			}
		}).then(response => {
			setSurcharges(response?.data?.surcharges || 1)
			setSurchargesAmount(response?.data?.surcharges_amount || 0)
			setPaymentProcessingByClien(response?.data?.payment_processing_by_client || 0)
			setIsFetching(false);
			dispatch(surchargesData({
				surcharges: response?.data?.surcharges || 1,
				surcharges_comment: response?.data?.surcharges_comment || '',
				isLoading: false
			}))

			if (response.code === 200) {
				if (response.data.order_uuid && !orderUuid) {
					setOrderUuid(response.data.order_uuid);
				}
				if (response.data.table && !tableNumber) {
					setTableNumber(response.data.table);
				}
				const { order_items } = response.data;
				if (order_items && Array.isArray(order_items) && order_items.length) {
					restoreItemsToCart(menu, order_items);
				}

				if (window.location.search) {
					const paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
					const cart = paramFromUrl.cart ? paramFromUrl.cart : null;
					if (cart === '1' && props.windowSize.windowWidth > 1024) {
						setCartVisible(1);
					}
				}
			}
		}).catch(error => {
			console.log(error);
		});
	}

	const restoreItemsToCart = (menu, items) => {
		const newCartList = [...cartList];
		items.forEach((item) => {
			const modifiers = [];
			if (item.modifiers && item.modifiers.length) {
				item.modifiers.forEach((modifier) => {
					modifiers.push(modifier.modifier_id)
				});
			}
			const addToCart = {
				item: false,
				quantity: item.quantity,
				comments: item.comments,
				order_item_id: item.id,
				modifiers
			};

			if (menu.constructor === Object && Object.entries(menu).length !== 0) {
				if (menu.categories && Array.isArray(menu.categories) && menu.categories.length) {
					menu.categories.forEach((category) => {
						const { items } = category;
						if (items && Array.isArray(items) && items.length) {
							category.items.forEach((itemObj) => {
								if (itemObj.id === item.product_id && !addToCart.item) {
									addToCart.item = { ...itemObj };
								}
							});
						}
					});
					if (addToCart.item) {
						newCartList.push(addToCart);
					}
				}
			}
		});
		setCartList(newCartList);
	}
	const openItem = (item) => {
		setVisibleItem(1);
		setActiveItem(item);
	}
	const hideProduct = () => {
		setVisibleItem(0);
		setActiveItem({});
		setProductQuantity(1);
		setProductComments('');
		setProductModifiers([]);
	}

	const singleProductCss = {
		width: props.windowSize.windowWidth < 1024 ? props.windowSize.windowWidth : "800px",
		height: props.windowSize.windowWidth < 1024 ? props.windowSize.windowHeight : "700px",
		minHeight: props.windowSize.windowWidth < 1024 ? props.windowSize.windowHeight : "600px",
		opacity: visibleItem ? 1 : 0,
		zIndex: (visibleItem && props.windowSize.windowWidth < 1024) ? 1009 : -100
	};
	const singleProductWrapperCss = {
		width: props.windowSize.windowWidth < 1024 ? props.windowSize.windowWidth : "800px",
		height: props.windowSize.windowWidth < 1024 ? "fit-content" : "700px",
		opacity: visibleItem ? 1 : 0,
		zIndex: (visibleItem && props.windowSize.windowWidth < 1024) ? 1009 : -100
	};

	const changeProductQuantity = (operation = 'add') => {
		let actualQ = productQuantity;

		if (operation === 'add') {
			actualQ += 1;
			setProductQuantity(actualQ);
		}

		if (operation === 'remove') {
			actualQ -= 1;
			if (actualQ > 0) {
				setProductQuantity(actualQ);
			}
		}
	}

	const addToOrderWithConfirmation = () => {
		if (activeItem.is_alcohol === true && isAlcoholSalesForTakeaway === false) {
			setConfirmAlcoholShow(true)
		} else {
			addToOrder()
		}
	}

	const addToOrder = () => {

		const addToCart = {
			item: { ...activeItem },
			quantity: productQuantity,
			comments: productComments,
			modifiers: productModifiers,
			order_item_id: null
		};
		setIsFetching(true);
		fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/orders/addItem/', {
			method: 'POST',
			body: {
				branchUuid,
				orderUuid,
				menuId,
				tableNumber,
				productId: addToCart.item.id,
				quantity: productQuantity,
				comments: productComments,
				modifiers: productModifiers
			}
		}).then(response => {
			console.log(response);
			if (response.code === 200) {
				if (response.data.order_uuid && !orderUuid) {
					setOrderUuid(response.data.order_uuid);
				}

				const newCartList = [...cartList];
				addToCart.order_item_id = response.data.id;
				newCartList.push(addToCart);
				setCartList(newCartList);

				hideProduct();
			}

			setIsFetching(false);

		}).catch(error => {
			console.log(error);
		});
	}

	const cartEmpty = () => {
		let empty = 1;
		if (cartList && Array.isArray(cartList) && cartList.length) {
			empty = 0;
		}
		return empty;
	}

	const summModifiersPrice = (allModifierSets, activeModifiers) => {
		let totalMdsf = 0;

		if (allModifierSets && allModifierSets.length && activeModifiers && activeModifiers.length) {
			allModifierSets.forEach((modifierSet) => {
				modifierSet.modifiers.forEach((modifier) => {
					if (activeModifiers.indexOf(modifier.id) > -1 && modifier.price_add) {
						totalMdsf += correctModifierSurcharges(modifier.price_add, surcharges);
					}
				});
			})
		}
		return totalMdsf;
	}

	const countTotal = (params = false) => {
		let total = 0;
		if (cartList && Array.isArray(cartList) && cartList.length) {
			cartList.forEach((line) => {

				const { modifiers, item } = line;
				let totalModifiers = 0;
				if (modifiers && modifiers.length && item.modifierSets && item.modifierSets.length) {
					totalModifiers = summModifiersPrice(item.modifierSets, modifiers);
				}

				if (!params) {
					total += correctModifierSurcharges(line.quantity * parseFloat(line.item.price), surcharges) + totalModifiers * line.quantity;
				}

				if (params === 'noTaxes') {
					const gross = correctModifierSurcharges(line.quantity * parseFloat(line.item.price), surcharges) + line.quantity * totalModifiers;
					let net = gross;
					if (line.item.vat) {
						net = gross - (gross * line.item.vat / 100);
					}
					total += net;
				}
				if (params === 'onlyTaxes') {
					const gross = correctModifierSurcharges(line.quantity * parseFloat(line.item.price), surcharges) + totalModifiers * line.quantity;
					let taxes = 0;
					if (line.item.vat) {
						taxes = (gross * line.item.vat / 100);
					}
					total += taxes;
				}
			});
		}

		return `$${total.toFixed(2)}`;
	};

	const editItem = (index, quantity) => {
		const newCartList = [...cartList];


		console.log('editItem', newCartList[index]);
		setIsFetching(true);
		fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/orders/editItem/', {
			method: 'POST',
			body: {
				branchUuid,
				orderUuid,
				order_item_id: newCartList[index].order_item_id,
				quantity
			}
		}).then(response => {
			console.log(response);
			setIsFetching(false);
			if (response.code === 200) {
				newCartList[index].quantity = quantity;
				setCartList(newCartList);
			}
		}).catch(error => {
			console.log(error);
		});
	}

	const removeItem = (index) => {
		const newCartList = [...cartList];
		console.log('removeItem', newCartList[index]);
		setIsFetching(true);
		fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/orders/removeItem/', {
			method: 'POST',
			body: {
				branchUuid,
				orderUuid,
				order_item_id: newCartList[index].order_item_id
			}
		}).then(response => {
			console.log(response);
			setIsFetching(false);
			if (response.code === 200) {
				const newCopy = [];
				newCartList.forEach((line, i) => {
					if (i !== index) {
						newCopy.push(line);
					}
				});
				setCartList(newCopy);

			}
		}).catch(error => {
			console.log(error);
		});
	}

	const changeProductCardQuantity = (index, operation = 'add') => {
		let actualQ = cartList[index].quantity;
		if (operation === 'add') {
			actualQ += 1;
		}
		if (operation === 'remove') {
			actualQ -= 1;
		}
		if (actualQ > 0) {
			editItem(index, actualQ);
		} else {
			removeItem(index);
		}
	}
	const cartScreenCss = {
		width: (props.windowSize.windowWidth < 1024) ? props.windowSize.windowWidth : "auto",
		height: props.windowSize.windowHeight,
		minHeight: props.windowSize.windowHeight,
		opacity: cartVisible ? 1 : 0,
		zIndex: cartVisible ? 1009 : -100
	};

	const modalProductCss = {
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)"
	};

	const containerStyle = {
		width: props.windowSize.windowWidth,
		height: props.windowSize.windowHeight,
		minHeight: props.windowSize.windowHeight,
		overflow: cartVisible || visibleItem ? 'auto' : 'auto',
		zIndex: (props.windowSize.windowWidth < 1024 && visibleItem) ? 1009 : 0
	};

	const checkoutFinal = () => {
		//setCartList([]);
		//setCartVisible(0);

		//if (Auth.isUserAuthenticated()) {
		props.history.push(`/${branchUrl}/checkout/${orderUuid}`);
		// } else {
		// 	props.history.push(`/${branchUrl}/signup?order=${orderUuid}`);
		// }
	}
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}
	const prevPage = () => {
		if (pageNumber > 1) {
			setPageNumber(pageNumber - 1);
		}
	}
	const nextPage = () => {
		if (pageNumber < numPages + 1) {
			setPageNumber(pageNumber + 1);
		}
	}

	const online_order = branchObj?.online_order
	const title_in_app = branchObj?.title_in_app
	const menu_type = branchObj?.menu_type

	const menuPdf = branchObj?.menuPdf

	if (menuPdf && menu_type === 'scan') {
		const viewport = document.querySelector("meta[name=viewport]");
		viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, user-scalable=1');
	}
	const addModifierToItem = (modifier_id) => {
		const copy = [...productModifiers];
		const index = copy.indexOf(modifier_id);

		if (index > -1) {
			delete copy[index];
		} else {
			copy.push(modifier_id);
		}

		const final = copy.filter(el => !!el);
		setProductModifiers(final);


	}
	const findRadioChecked = (totalList) => {
		let findAny = null;
		totalList.forEach((item) => {
			if (productModifiers.indexOf(item.id) > -1) {
				findAny = item.id;
			}
		})
		return findAny;
	}

	const addModifierRadio = (modifierSetId, e) => {
		const modifier_id = parseInt(e.target.value, 10);
		const copy = { ...radioSelectorHandler };
		const copyModifiers = [...productModifiers];

		if (copy.hasOwnProperty(modifierSetId)) {
			const oldModifierId = copy[modifierSetId];
			if (copyModifiers.indexOf(oldModifierId) > -1) {
				delete copyModifiers[copyModifiers.indexOf(oldModifierId)]
			}
		}

		copy[modifierSetId] = modifier_id;

		copyModifiers.push(modifier_id);

		setRadioSelectorHandler(copy);

		const final = copyModifiers.filter(el => !!el);
		setProductModifiers(final);

	}
	const resetRadioChoice = (modifierSetId, modifier_id) => {
		const copy = { ...radioSelectorHandler };

		copy[modifierSetId] = null;
		addModifierToItem(modifier_id);

		setRadioSelectorHandler(copy);
	}
	const checkIfChecked = (modifier_id) => {
		return productModifiers.indexOf(modifier_id) > -1;
	}

	const checkedModifiersFromSetCount = (modifierSet) => {
		const modifiersInSet = modifierSet.modifiers.map(m => m.id);
		let count = 0;
		productModifiers.forEach(modifier => {
			if (modifiersInSet.indexOf(modifier) !== -1) count++;
		});
		return count
	}

	const resetCheckboxChoice = (modifierSet) => {
		const modifiersInSet = modifierSet.modifiers.map(m => m.id);
		const newModifiers = productModifiers.filter(item => {
			return modifiersInSet.indexOf(item) === -1
		})
		setProductModifiers(newModifiers);
	}

	const renderModifierSets = (modifierSets) => {
		return <div style={{ marginBottom: '24px' }}>
			{modifierSets.map((modifierSet, key) => {
				const checkedRadioValue = findRadioChecked(modifierSet.modifiers);
				console.log('set', modifierSets)

				return (<div key={key} className="modifierSets">
					<div className="modifierSets__title" title={modifierSet.display_name}>
						{modifierSet.display_name}
						{modifierSet.required && <span className="modifierSets__required">(Required)</span>}
						{modifierSet.single_select && checkedRadioValue ?
							<a
								href="#reset"
								style={{ marginLeft: '10px', color: '#000', 'fontSize': '14px' }}
								onClick={e => resetRadioChoice(modifierSet.id, checkedRadioValue)}
							>Reset choice</a>
							: null}
						{!modifierSet.single_select && checkedModifiersFromSetCount(modifierSet) > 1 ?
							<a
								href="#reset"
								style={{ marginLeft: '10px', color: '#000', 'fontSize': '14px' }}
								onClick={e => resetCheckboxChoice(modifierSet)}
							>Reset choice</a>
							: null}
					</div>
					<ul className="modifierSets__list">
						{modifierSet.modifiers.map((modifier, i) => <li className="modifierSets__list-item" key={i}>
							<div className="modifierSets__option">
								<input
									id={`modifier${modifier.id}`}
									type={modifierSet.single_select ? 'radio' : 'checkbox'}
									name={`modifier${modifierSet.single_select ? modifierSet.id : modifier.id}`}
									onChange={e => !modifierSet.single_select ? addModifierToItem(modifier.id) : addModifierRadio(modifierSet.id, e)}
									value={modifier.id}
									checked={checkIfChecked(modifier.id)}
									className="modifierSets__checkbox-input"
								/>
								<label className="modifierSets__option-text" htmlFor={`modifier${modifier.id}`} style={{}} >
									<div style={{ display: "flex", alignItems: "center" }}>
										<span className="modifierSets__checkbox-icon"></span>
										<div className="modifierSets__checkbox-text">{modifier.display_name}</div>
									</div>
									{modifier.price_add ?
										surcharges === 1 ?
											<span className="modifierSets__option-price">{formModifierPriceString(modifier.price_add)}</span> :
											<div style={{alignItems: "flex-end", display: "flex", flexDirection: "column" }}>
												<div><span className="modifierSets__option-price-before" style={{ textDecoration: "line-through" }}>{formModifierPriceString(modifier.price_add)}</span></div>
												<div><span className="modifierSets__option-price">{formModifierPriceString(correctModifierSurcharges(modifier.price_add, surcharges))}</span></div>
											</div>
										: ''}
								</label>
							</div>
						</li>)}
					</ul>
				</div>);
			}
			)}
		</div>
	}

	const priceOpenItem = (item) => {
		let total = 0;
		let totalModifiers = 0;
		const { modifierSets } = item;
		if (modifierSets && modifierSets.length && productModifiers.length) {
			modifierSets.forEach((modifierSet) => {
				modifierSet.modifiers.forEach((modifier) => {
					if (productModifiers.indexOf(modifier.id) > -1 && modifier.price_add) {
						totalModifiers += parseFloat(correctModifierSurcharges(modifier.price_add, surcharges));
					}
				});
			});
		}

		if (item.price) {
			total += correctModifierSurcharges(parseFloat(item.price) * productQuantity, surcharges) + totalModifiers * productQuantity
		}


		return total.toFixed(2);
	}

	const priceCartItem = (line) => {
		const { item, quantity } = line;
		const { modifierSets } = item;

		let totalModifiers = 0;

		if (modifierSets && modifierSets.length && line.modifiers.length) {
			modifierSets.forEach((modifierSet) => {
				modifierSet.modifiers.forEach((modifier) => {
					if (line.modifiers.indexOf(modifier.id) > -1 && modifier.price_add) {
						totalModifiers += correctModifierSurcharges(parseFloat(modifier.price_add), surcharges);
					}
				});
			});
		}

		let total = 0;
		if (item.price) {
			total += parseFloat(item.price) * quantity * surcharges + totalModifiers * quantity
		}

		return total.toFixed(2);
	}
	const renderCartModifiersList = (line) => {
		const { item, modifiers } = line;
		const { modifierSets } = item;

		let modifiersRender = [];
		if (modifierSets && modifierSets.length && modifiers.length) {
			modifierSets.forEach((modifierSet) => {
				modifierSet.modifiers.forEach((modifier) => {
					if (modifiers.indexOf(modifier.id) > -1) {
						modifiersRender.push(<p>{modifierSet.display_name + ': ' + modifier.display_name + ''}</p>);
					}
				});
			});
		}
		return modifiersRender
	}

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
		},
	};
	const closeModal = () => {
		setConfirmAlcoholShow(false)
		addToOrder()
	}

	const isAllRequiredModifiersChecked = (item) => {
		if (!item || !item.modifierSets || item.modifierSets.length === 0) { return true }
		let flag = true;
		for (let i = 0; i < item.modifierSets.length; i++) {
			const set = item.modifierSets[i];
			if (set.required && !findRadioChecked(set.modifiers)) {
				flag = false
				break;
			}
		}
		return flag;
	}

	const renderSingleProduct = (isInModal) => {
		return (<div className={`singleProduct${isInModal ? "__modal" : ""}`} style={singleProductCss}>
			<button onClick={hideProduct} className="singleProduct__close" alt="close single product">
				<IconCloseProduct className="singleProduct__close--icon" />
			</button>
			<div className="singleProduct__wrapper" style={isInModal ? singleProductWrapperCss : singleProductCss}>
				<div className={"singleProduct__cover " + activeItem.orientation} style={{
					backgroundImage: activeItem.imageName ? `url(${activeItem.thumbnail})` : ''
				}}>
				</div>
				<div className="singleProduct__body">
					<div className="singleProduct__card">
						<div className="singleProduct__header">
							<div className="singleProduct__title">{activeItem.displayName}</div>
							{surcharges === 1 ?
								<div className="singleProduct__price">${activeItem.price}</div>
								:
								<>
									<div className="singleProduct__price" style={{
										textDecoration: "line-through",
										fontSize: "20px"
									}}>${activeItem.price}</div>
									<div
										className="singleProduct__price">{showTotalWithSurcharges(activeItem.price, surcharges)}</div>
								</>
							}
						</div>
						{activeItem.description && <p className="singleProduct__desc">{activeItem.description}</p>}
						{
							online_order ?
								<React.Fragment>
									<div className="singleProduct__quantity" style={{ margin: '10px 0 24px 0' }}>
										<button
											className="singleProduct__quantityBtn singleProduct__quantityBtn--remove"
											onClick={() => changeProductQuantity('remove')}
											alt="reduce product quantity">
											<IconProductRemove className="singleProduct__quantityBtn--icon" />
										</button>
										<div className="singleProduct__quantityNumber">{productQuantity}</div>
										<button
											className="singleProduct__quantityBtn singleProduct__quantityBtn--add"
											onClick={() => changeProductQuantity('add')}
											alt="increase product quantity">
											<IconProductAdd className="singleProduct__quantityBtn--icon" />
										</button>
									</div>

								</React.Fragment>
								: null
						}

						{
							activeItem.modifierSets && activeItem.modifierSets.length ?
								renderModifierSets(activeItem.modifierSets) : null
						}
						{
							online_order ?
								<React.Fragment>
									<textarea
										className="singleProduct__comments"
										rows="1"
										placeholder="Comments"
										value={productComments}
										onChange={e => setProductComments(e.target.value)}
									></textarea>
								</React.Fragment>
								: null
						}
					</div>

					{online_order ? <button
						className={(isFetching || !isAllRequiredModifiersChecked(activeItem)) ? "singleProduct__add__disabled" : "singleProduct__add"}
						onClick={addToOrderWithConfirmation}
						disabled={isFetching || !isAllRequiredModifiersChecked(activeItem)}>
						<div>${priceOpenItem(activeItem)}</div>
						<div className="singleProduct__addLabel">Add to Order</div>
					</button> : null}
				</div>
			</div>
		</div>)
	}

	const renderCartScreen = () => {
		return (
			<>
				{
					(cartEmpty() && props.windowSize.windowWidth >= 1024)
						?
						<>
							<p style={{color: "#a1a1a1", fontSize: "1.3rem"}}>Your cart is empty</p>
							<p style={{
								color: "#a1a1a1",
								fontSize: "1rem"
							}}>Add items to start your order</p>
						</>
						:

						<div className="cartScreen" style={cartScreenCss}>
							<div className="cartScreen__top">
								<div className="menu__logo">
									<div className="cartScreenMenu__container">
										<img src={dezyHeaderLogoRed} alt="dezy logo" className="menu__logoImg" />
									</div>
								</div>
								{!isOrderDrawerOpen && <div className="cartScreenHeader">
									<div className="cartScreenMenu__container">
										<div className="cartScreenHeader__inner">
											{props.windowSize.windowWidth < 1024 && <button className="cartScreenHeader__back" onClick={() => setCartVisible(0)}>
												<IconCloseProduct className="cartScreenHeader__back--icon" />
												Add more
											</button>}
											<div className="cartScreenHeader__title">My Order</div>
										</div>
									</div>
								</div>}
							</div>
							<div className="cartScreenBody">
								{cartEmpty() ?
									<div className="cartScreenBody__empty">Cart is empty</div>
									:
									<div className="cartScreenBody__wrapper">
										<div className="cartScreenList">
											{cartList.map((line, index) => (
												<div className="cartScreenItem" key={index}>
													<div className={"cartScreenItem__cover " + line.item.orientation} style={{
														backgroundImage: line.item.imageName ? `url(${line.item.thumbnail})` : ''
													}}></div>
													<div className="cartScreenItem__middle">
														<div className="cartScreenItem__title">{line.item.displayName}</div>
														{line.comments && <div className="cartScreenItem__comments">{line.comments}</div>}
														{line.modifiers && line.modifiers.length ? <div className="cartScreenItem__comments">{renderCartModifiersList(line)}</div> : null}
														<div className="cartScreenItem__quantity">
															<button
																className="cartScreenItem__quantityBtn cartScreenItem__quantityBtn--remove"
																onClick={() => changeProductCardQuantity(index, 'remove')}
																alt="reduce product quantity"
																disabled={isFetching}
															>
																<IconProductRemove className="cartScreenItem__quantityBtn--icon" />
															</button>
															<div className="cartScreenItem__quantityNumber">{line.quantity}</div>
															<button
																className="cartScreenItem__quantityBtn cartScreenItem__quantityBtn--add"
																onClick={() => changeProductCardQuantity(index, 'add')}
																alt="increase product quantity"
																disabled={isFetching}
															>
																<IconProductAdd className="cartScreenItem__quantityBtn--icon" />
															</button>
														</div>
													</div>
													<div className="cartScreenItem__price">
														${priceCartItem(line)}
													</div>
												</div>

											))}
										</div>
									</div>
								}
							</div>
							<div className="cartScreenFooter">
								<div className="cartScreenTotal">
									<div className="cartScreenTotal__item">
										GST
										<span className="cartScreenTotal__price">{countTotal('onlyTaxes')}</span>
									</div>
									{surcharges != 1 ?
										<div className="cartScreenTotal__item">
											{surcharges > 1 ? `Restaurant surcharges` : `Restaurant discount`}
											<span className="cartScreenTotal__price cartScreenTotal__price--red">{surchargesToString(surcharges)}</span>
										</div>
										: null}
									<div className="cartScreenTotal__item">
										Total Amount Payable
										<span className="cartScreenTotal__price cartScreenTotal__price--red">{countTotal()}</span>
									</div>
								</div>
								<div className="cartScreenFooter__box">
									<button className="cartScreenFooter__checkout" onClick={checkoutFinal} disabled={cartEmpty()}>Submit order</button>
								</div>
							</div>
						</div>
				}	</>
		)
	}

	const [isOrderDrawerOpen, setIsOrderDrawerOpen] = useState(false);
	const handleCloseOrderDrawer = () => {
		setIsOrderDrawerOpen(false);
		setCartVisible(1);
	}

	return (
		<>
			<Modal
				isOpen={confirmAlcoholShow}
				onAfterOpen={() => { }}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="Example Modal"
			>
				<h2>Alcohol is not available for takeout</h2>
				<Button type='button' style={{ width: '100%' }} onClick={closeModal}>I understand</Button>
			</Modal>
			{!surcharge_data.isLoading && surcharges !== 1 ?
				<Modal
					isOpen={!surcharge_data.wasShowed}
					onAfterOpen={() => { }}
					onRequestClose={() => { }}
					style={customStyles}
					contentLabel="Example Modal"
				>
					<h2>{`${surchargesLogoMessageString(surcharge_data.surcharges)} ${surcharge_data.surcharges_comment}`}</h2>
					<Button type='button' style={{ width: '100%' }} onClick={() => dispatch(switchShow(true))}>I understand</Button>
				</Modal>
				: null}
			<div id="appContainer" className="app-container" style={containerStyle} data-animation-spinning={isFetching || surcharge_data.isLoading}>
				<div className="menu">
					<div className="menu__top" >
						{MenuTop(restaurantName, logo, banner, title_in_app)}
					</div>

					{menu_type === 'online' ? <div className="menuBody">
						{jsonMenu.constructor === Object && Object.entries(jsonMenu).length !== 0 ?
							<React.Fragment>
								<div className="menuCategories">
									<div className="menuCategories__wrapper">
										<div id="menuCategories" className="menuCategories__wrapperInner">
											<div className="menuCategories__inner">
												<ul className="menuCategories__list">
													{
														jsonMenu.categories.map((category, index) => (
															<li
																key={index}
																id={`menu_category_${category.id}`}
																data-category-id={category.id}
																className={`menuCategories__item ${visibleCategory === category.id ? 'menuCategories__item--active' : ''}`}
																onClick={() => scrollTo(category.id)
																}>
																<span className=
																	{`menuCategories__link ${visibleCategory === category.id ? 'menuCategories__link--active' : ''}`}>
																	{category.displayName}
																</span>
															</li>
														))
													}
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div style={{ display: 'flex', width: props.windowSize.windowWidth >= 1024 ? '71%' : '100%' }}>
									<div className="menuList">
										{
											jsonMenu.categories.map((category, index) => (
												<div id={`section_category_${category.id}`} data-category-id={category.id} key={index}>
													<div id={`menu_category_${category.id}`} data-category-id={category.id} className="menuList__catTitle">
														{category.displayName}
													</div>
													<div className="menuList__wrapper">
														{category.items.map((item, index) =>
															<div key={index} className="menuItem" onClick={() => openItem(item)}>
																{<div className="menuItem__imageWrapper">
																	<div className={"menuItem__cover " + item.orientation} style={{
																		backgroundImage: item.thumbnail && `url(${item.thumbnail} )`
																	}}></div>
																</div>}
																<div className="menuItem__textWrapper">
																	<div className="menuItem__title">{item.displayName}</div>
																	{item.description &&
																		<p className="menuItem__desc">{item.description}</p>}
																	{surcharges === 1 ?
																		<div className="menuItem__price">${item.price}</div> :
																		<div style={{ display: "flex" }}>
																			<div className="menuItem__price" style={{
																				textDecoration: "line-through",
																				marginRight: "20px"
																			}}>${item.price}</div>
																			<div
																				className="menuItem__price">{!!item.price ? showTotalWithSurcharges(item.price, surcharges) : ''}</div>
																		</div>
																	}
																</div>
															</div>
														)}
													</div>
												</div>
											))
										}
									</div>
									{props.windowSize.windowWidth >= 1024
										?
										<SlideDrawer isOpen={true}
											handleClose={handleCloseOrderDrawer}
											styles={cartEmpty() ? { justifyContent: 'center', alignItems: 'center' } : {}}
										>
											{renderCartScreen()}
										</SlideDrawer>
										: renderCartScreen()
									}
								</div>
							</React.Fragment>
							: null}

					</div> :
						<React.Fragment>{menuPdf ? <div>
							<Document
								file={menuPdf}
								onLoadError={console.error}
								onLoadSuccess={onDocumentLoadSuccess}
							>
								<Page pageNumber={pageNumber} width={props.windowSize.windowWidth} />
							</Document>
							<div className="menuPDF__buttons">
								<Button type="button" onClick={prevPage} disabled={pageNumber === 1}>
									Back
								</Button>
								<Button type="button" onClick={nextPage} disabled={pageNumber === numPages}>
									Next
								</Button>
							</div>
							<p style={{ textAlign: 'center' }}>Page {pageNumber} of {numPages}</p>
						</div> : null} </React.Fragment>
					}
				</div>

				 {!cartEmpty() && (props.windowSize.windowWidth <1024) && <div className="cartFloated" onClick={() => {
					setCartVisible(1);
				}}>
					<div>{countTotal()}</div>
					<div className="cartFloated__label">View Order</div>
				</div>}
				{props.windowSize.windowWidth >= 1024 ? <Modal
					style={{
						overlay: {
							position: 'fixed',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							backgroundColor: 'rgba(255, 255, 255, 0.75)'
						},
						content: {
							position: 'absolute',
							top: 'calc(50% - 370px)',
							left: 'calc(50% - 420px)',
							right: 'unset',
							bottom: 'unset',
							border: '1px solid #ccc',
							background: '#fff',
							overflow: 'auto',
							WebkitOverflowScrolling: 'touch',
							borderRadius: '20px',
							outline: 'none',
							padding: '20px'
						}
					}}
					isOpen={!!visibleItem}
					onAfterOpen={() => { }}
					onRequestClose={() => { hideProduct(); }}
					contentLabel="Example Modal">
					{renderSingleProduct(true)}
				</Modal> :
					renderSingleProduct(false)}
				{props.windowSize.windowWidth >= 1024 ? <SlideDrawer isOpen={true}
					handleClose={handleCloseOrderDrawer}>{renderCartScreen()}</SlideDrawer> : renderCartScreen()
				}

			</div>
		</>
	);
};

export default withWindowSizeListener(Menu)
