import React from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import i18n from 'i18next';
import AppWrapper from 'components/AppWrapper/AppWrapper';
import qs from 'qs';
import Auth from './Auth';
import fetchJSON from './fetchJSON';
import { customStyles } from './customStyles';

/**
 * when entering the page to check if the user has access to them
 */
const checkRole = (branchUrl, next) => {
	// create an AJAX request
	fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/auth/role', {
		method: 'post',
		body: {
			branchUrl
		}
	}).then(data => {
		console.log('data', data);
		if (data.language && data.language !== i18n.language) {
			i18n.changeLanguage(data.language);
		}
		if (data.success) return next(data.role, data.success, data);
		if (data.role === 'wrongToken') return next(data.role, false, data);
		return next(false, false, data);
	}).catch((error) => {
		if (error.status === 500) {
			return next('error500');
		} else {
			return next(false);
		}
	});
};


export const checkAccessWrap = (Component, noWrap = false, type = 'private') => {
  class AuthenticatedComponent extends React.Component {
		state = {
			renderPage: false,
			role: '',
			branchObj: false
		}
    UNSAFE_componentWillMount() {
      this.checkAuth();
    }
    insertStyles = () => {
    	const { branchObj } = this.state;
    	const main_color_theme = _.get(branchObj, 'main_color_theme');
    	if (main_color_theme) {
    		customStyles(main_color_theme);
    	}
    }
    checkAuth() {
    	const { branchUrl } = this.props.match.params;
    	if (branchUrl === 'undefined') {
    		this.props.history.push('/');
    		return;
    	}

    	checkRole(branchUrl, (role = false, success = false, data = {}) => {

				const location = this.props.location;
				const redirect = location.pathname + location.search;

				const branchTitle = _.get(data, 'branchObj.title');
				//menu__logoImg
				this.setState({
					branchObj: data.branchObj,
					role
				}, this.insertStyles);

				if (type === 'public' && branchTitle) {
					this.setState({
						renderPage: true,
					});
					return;
				}
				if (!success) {
					if (role === 'error500') {
						this.props.history.push(`/error500?redirect=${redirect}`);
					}  else if (role === 'wrongToken') {
						this.props.history.push('/logout');
					} else if (role) {
						this.props.history.push('/');
					}
					else {
						this.props.history.push(branchUrl ? `/${branchUrl}/login?redirect=${redirect}` : `/login?redirect=${redirect}`);
					}

				} else {
					this.setState({
						renderPage: true,
						role: role
					});
				}
			})
    }
    render() {
    	const { role, branchObj } = this.state;
      return this.state.renderPage
        ?
        <React.Fragment>
        	{ noWrap
        	?
					<Component { ...this.props } />
        	: <AppWrapper role={role} { ...this.props } branchObj={branchObj} >
        		<Component { ...this.props } branchObj={branchObj} role={role} />
        	</AppWrapper> }
        </React.Fragment>
        : null;
    }
  }
	return AuthenticatedComponent;
}

export const checkLogin = (redirectTo, next) => {
	if (Auth.isUserAuthenticated()) {
		let redirectFromUrl = null;
		if (window.location.search) {
			let param = window.location.search;
			let paramFromUrl = qs.parse(param, { ignoreQueryPrefix: true });
			let redirect = paramFromUrl.redirect ? paramFromUrl.redirect : false;
			if (redirect) {
				redirectFromUrl = redirect;
			}
		}
		return ( <Redirect to={{ pathname: redirectFromUrl ? redirectFromUrl : redirectTo }} /> );
	} else {
		return next();
	}
};

export default checkAccessWrap;
