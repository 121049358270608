import React, { useEffect, useState }  from 'react';
import Settings from 'services/config/Settings';
import { withTranslation } from 'react-i18next';
import { withWindowSizeListener } from 'react-window-size-listener';
import Button from 'components/Button/Button';
import fetchJSON from 'services/utils/fetchJSON';
import { Link } from 'react-router-dom';
import Analytics from 'services/utils/Analytics';
import moment from 'moment-timezone';
import _ from 'lodash';
import { ReactComponent as IconCloseProduct } from 'components/images/icon-close-single-product.svg';
import '../styles.less';
import {
	CardElement,
	Elements,
	useStripe,
	useElements
} from '@stripe/react-stripe-js';


const CardFields = (props) => {
	const { cartError, setCartError } = props;

	const cardChanged = (form) => {
		if (form.error) {
			const { error } = form;
			if (error && error.message) {
				setCartError(error.message);
			}
		} else {
			setCartError('');
			if (form.complete) {
				Analytics('CardAdded')
			}
		}
	}
	const { visible } = props;


	return (
		<div className={"sbCardForm "+(visible ? 'visible' : 'hide')}>
			<div id="cardForm">
				<CardElement
					onChange={cardChanged}
					options={{
						hidePostalCode: true,
						style: {
							base: {
								fontSize: '16px',
								color: '#424770',
								lineHeight: '42px',
								'::placeholder': {
									color: '#aab7c4',
								},
							},
							invalid: {
								color: '#E01515',
							},
						},
					}}
				/>
				{cartError ? <div className="sbCardForm__error">{cartError}</div> : null }
			</div>
		</div>
	)
}


class Checkout extends React.Component {
	constructor(props) {
    super(props);
	    this.state = {
	    pageVisible: false,
			isFetching: false,
			branchUuid: null,
			branchName: '',
			orderUuid: null,
			orderTotal: 0,
			payment_methods: [],
		  cardActive: true,
		  cashActive: true,
		  card_pay_at_counterActive: true,
		  paypalActive: true,
		  paymentMethod: '',
		  showNewMethods: true,
		  selectedMethod: false,
		  cardValidateError: '',
		  cardFormReady: false,
		  newMethod: false,
		  methodLabel: '',
		  applePayActive: false,
		  applePayReady: false,
		  paypalReady: false,
		  paymentCurrency: 'AUD',
		  serverMessage: '',
  		serverMessageType: '',
  		deviceData: '',
  		paymentFetching: false,
  		is_paid: 0,
  		order: {},
  		payment_in_app: false,
  		payment_manual: true,
			payment_processing_by_client: 0
		};
    this.cardFormRef = React.createRef();
  }

	componentDidMount = () => {
		const { t } = this.props;
		document.title = `${t('checkoutPage.documentTitle', { defaultValue: 'Checkout' })} | ${Settings.title}`;

    const { orderUuid } = this.props.match.params;


    this.setState({
    	payment_methods: [
    		// {
		    //   label: 'cash',
		    //   viewLabel: t('paymentScreen.paymentMethods.Cash', { defaultValue:  'Cash' }),
		    //   token: 'cash',
		    //   newMethod: true
		    // },
		    {
		      label: 'card_pay_at_counter',
		      viewLabel: t('paymentScreen.paymentMethods.cardAtCounter', { defaultValue:  'Pay at counter' }),
		      token: 'pay_at_counter',
		      newMethod: true
		    },
		    {
		      label: 'card',
		      viewLabel: t('paymentScreen.paymentMethods.creditCard', { defaultValue:  'Pay now online' }),
		      token: 'card',
		      newMethod: true
		    },
		    {
		      label: 'applePay',
		      viewLabel: t('paymentScreen.paymentMethods.ApplePay', { defaultValue:  'Apple Pay' }),
		      token: 'applePay',
		      newMethod: true
		    },
		    // {
		    //   label: 'paypal',
		    //   viewLabel: t('paymentScreen.paymentMethods.PayPal', { defaultValue:  'PayPal' }),
		    //   token: 'paypal',
		    //   newMethod: true
		    // }
		  ],
		  orderUuid
    });
    this.getOrder(orderUuid);
	}
	componentWillUnmount = () => {
    this.mounted = false;
  }
	 // this.setState({
		//     		cardValidateError: t('addCardForm.cardValidationError', { defaultValue:  'Card validation error, check your card' })
		//     	})
	changeFailMessage = (msg) => {
		console.log('changeFailMessage', msg);
		this.props.showError(msg);
	}
	closeFail = (failStatus, failMethod) => {
		console.log('closeFail', failStatus, failMethod);
	}
	handlePaymentMethod = (payload = false) => {
		const { t } = this.props;
		this.setState({
			paymentFetching: true,
			serverMessage: '',
	    serverMessageType: ''
		});
		const { deviceData, newMethod, methodLabel, orderUuid, branchUuid } = this.state;

		let body = {
			source: methodLabel,
			orderUuid,
			branchUuid
		};

		if (deviceData) {
			body.device_data = deviceData;
		};

		if (newMethod) {
			body.payment_method_nonce = payload.nonce;
		}

		fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/checkout/pay', {
			method: 'post',
			body
		}).then(data => {
			console.log(data);
			this.setState({
				paymentFetching: false
			});
			if (data.code === 200) {
				this.successPayment(data.message);
			} else {
				//this.changeFailMessage(data.message);
				this.serverFail(data.message, 'serverSide');
			}
		}).catch(error => {
			console.log(error);
			this.serverFail(t('payInvoice.server_error', { defaultValue: 'Server error catch' }), 'serverSide');
		});
	}
	offlineMethod = () => {
		const { methodLabel, orderUuid, branchUuid } = this.state;
		const { t } = this.props;
		this.setState({
			paymentFetching: true,
			serverMessage: '',
	    serverMessageType: ''
		});


		let body = {
			paymentType: methodLabel,
			orderUuid,
			branchUuid
		};

		fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/checkout/pay-offline', {
			method: 'post',
			body
		}).then(data => {
			console.log(data);
			this.setState({
				paymentFetching: false
			});
			if (data.code === 200) {
				this.goToNextStep();
			}

		}).catch(error => {
			console.log(error);
			this.serverFail(t('payInvoice.server_error', { defaultValue: 'Server error catch' }), 'serverSide');
		});

	}
	successPayment = (msg) => {
		// this.props.showError(msg, 'success');
		this.goToNextStep(true);
		// this.getOrder(orderUuid);
	}
	goToNextStep = (paid = false) => {
		const { orderUuid } = this.state;
		const { branchUrl } = this.props.match.params;
		this.props.history.push(`/${branchUrl}/orders/${orderUuid}${paid ? '/receipt' : ''}`);
	}
	applePayAvailable = (callback = false) => {
		/*eslint-disable */
    if (window.ApplePaySession) {
      try {
        if (ApplePaySession.canMakePayments()) {
        	const { payment_in_app } = this.state;
        	if (payment_in_app) {
        		const toState = {
	            applePayActive: true
	          };
	          Analytics('Apple pay avaliable');
	          this.setState(toState);
	          if (callback) callback();
        	}
        }

      } catch (e) {
        // console.log(e);
      }
    }
    /*eslint-enable */
  }
  getOrder = (orderUuid) => {
  	this.setIsFetching(true);
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/checkout/getOrder/', {
      method: 'POST',
      body: {
      	orderUuid
      }
    }).then(response => {
      console.log(response);
      this.setIsFetching(false);
      if (response.code === 200) {
      	const { order, timezone } = response.data;
      	order.submitted_at = order.submitted_at ? moment.utc(order.submitted_at).tz(timezone).format('MM/DD/YY h:mm a') : null;

      	const payment_in_app_value = _.get(response, 'data.branch.payment_in_app')
      	const stripe_charges_enabled = _.get(response, 'data.branch.stripe_charges_enabled');

      	let payment_in_app = false;
      	if (payment_in_app_value && stripe_charges_enabled) {
      		payment_in_app = true;
      	}
      	const payment_manual = _.get(response, 'data.branch.payment_manual')

      	// console.log('payment_manual', payment_manual);

      	let cardActive = true;
		    let paypalActive = true;
		    const { order_type } = order;

		    if (response.data.order.is_paid) {
      		// console.log('response.data.order', response.data.order)
      		this.goToNextStep();
      		return;
      	}

      	if (!payment_in_app) {
      		cardActive = false;
      		paypalActive = false;
      	}

      	let cashActive = true;
		  	let card_pay_at_counterActive = true;

      	if (!payment_manual) {
      		cashActive = false;
      		card_pay_at_counterActive = false;
      	}

      	console.log('response.data.order', response.data.order);

      	this.setState({
      		orderTotal: response.data.order.total,
      		branchUuid: response.data.branchUuid,
      		branchName: response.data.branchName,
      		is_paid: response.data.order.is_paid,
      		order: response.data.order,
      		payment_in_app,
      		cardActive,
      		paypalActive,
      		cashActive,
      		card_pay_at_counterActive,
					payment_processing_by_client: response.data.order.payment_processing_by_client
      	}, () => {
      		// this.applePayAvailable
      		if (!payment_in_app && order_type === 'dine_in') {
	      		this.offlineMethod();
	      		return;
	      	}
	      	this.setState({
	      		pageVisible: true
	      	})
      	});
      }
     }).catch(error => {
        console.log(error);
     });
  }
  orderChanged = () => {

  }
  onPaymentChanged = (method) => {
  	console.log('method', method);

    this.setState({
    	newMethod: method.newMethod,
    	methodLabel: method.label,
      selectedMethod: method,
      paymentMethod: method.token
    }, () => {
    	if (method.label === 'paypal') {
	  		this.setupPayPal();
	  	}
	  	this.orderChanged();
    });
  }
  setIsFetching = (param) => {
  	this.setState({
  		isFetching: param
  	})
  }
  doPaymentNow = (payload = false) => {

		console.log('doPaymentNow', payload);

		setTimeout(() => {
			this.handlePaymentMethod(payload);
		}, 100)
	}

  payWithCard = () => {
  	// if (this.cardFormRef && this.cardFormRef.current) {
  	// 	this.cardFormRef.current.dispatchEvent(new Event("submit"));
  	// }

  	console.log('submitForm')
  	this.props.submitForm();

  }
  renderPaymentMethods = () => {
  	const { payment_methods, paymentMethod, showNewMethods, cardValidateError, cardFormReady } = this.state;
    if (payment_methods.length) {
      return payment_methods.map((method, key) => {
		  console.log(method)
        let visible = true;
        if (method.newMethod) {
          const stateName = method.label+'Active';
          visible = this.state[stateName];
          if (method.newMethod && !showNewMethods) {
            visible = false;
          }
        }
        return (<li className={"checkoutPM__item checkoutPM__methods "+(visible ? 'active' : 'hide')} key={key}>
            <input
              id={`paymentMethod-${method.token}`}
              className="checkoutPM__input"
              type="radio"
              name="paymentMethod"
              value={ method.token }
              checked={ paymentMethod === method.token }
              onChange={ e => this.onPaymentChanged(method) }
              disabled={['applePay', 'paypal'].indexOf(method.token) > -1}
            />
            <label htmlFor={`paymentMethod-${method.token}`} className="checkoutPM__label">
              <span className={`checkoutPM__platformIcon ${method.label}`}></span>
              <span className={"checkoutPM__title paymentTitle "+(method.newMethod ? 'new' : 'old')}>{method.viewLabel}</span>
            </label>
            {
            	method.token === 'card' ?
            	<CardFields
            		formRef={this.cardFormRef}
            		cardFormReady={cardFormReady}
            		cardValidateError={cardValidateError}
            		visible={paymentMethod === 'card' && method.token === 'card'}
            		{...this.props}
          		/>
            	: null
            }
          </li>
        );
      })
    }

    return null;
  }
  serverFail = (failServer) => {
		// const { orderId } = this.props;
		console.log('serverFail!')
		this.props.showError(failServer);
	}

  showError = (msg, type = 'error') => {
  	this.setState({
  		serverMessage: msg,
  		serverMessageType: type
  	}, () => {
  		setTimeout(() => {
  			this.setState({
		  		serverMessage: '',
		  		serverMessageType: ''
		  	})
  		}, 3000);
  	});
  }
  noMethodSelected = () => {
  	this.props.showError('Please, choose a payment method.');
  }

  caluclateCardFee = (total) => {
		const roundAccurately = (number, decimalPlaces) => Number(`${Math.round(`${number}e${decimalPlaces}`)}e-${decimalPlaces}`);
		const fee = {
			domestic: 1.75,
			international: 2.9,
			fixed: 0.30
		}

		const domestic = `$${(((total + fee.fixed) / (1 - fee.domestic / 100)) - total).toFixed(2)}`
		const international = `$${(((total + fee.fixed) / (1 - fee.international / 100)) - total).toFixed(2)}`
		return { domestic, international }
	}

  render() {
  	const {
  		// branchUuid,
  		orderTotal,
  		methodLabel,
  		serverMessageType,
  		is_paid,
  		isFetching,
  		orderUuid,
  		pageVisible,
			payment_processing_by_client
  		// order
  	} = this.state;
  	const {
  		formPaymentError,
  		isSubmitting
  	} = this.props;

  	const { branchUrl } = this.props.match.params;
  	const containerStyle = {
	    width: this.props.windowSize.windowWidth,
	    height: this.props.windowSize.windowHeight,
	    minHeight: this.props.windowSize.windowHeight,
	  };
	  if (!pageVisible) {
	  	containerStyle.opacity = 0;
	  }

	  return(
			<div id="appContainer" className="app-container" style={containerStyle} data-animation-spinning={isSubmitting || isFetching}>
				<div className="cartScreen__top">
					<div className="checkout__container">
						<div className="cartScreen__top cartScreen__top--50">

			        <div className="cartScreenHeader">
			        	<div className="menu__container">
			        		<div className="cartScreenHeader__inner">
				        		{ orderUuid && <Link to={`/${branchUrl}/checkout/${orderUuid}?r=0`} className="cartScreenHeader__back">
				        		<IconCloseProduct className="cartScreenHeader__back--icon" />
				        		Back
				        		</Link> }
			        		</div>
			        	</div>
			        </div>
			      </div>
		      </div>
	      </div>
	      { !is_paid ?
	      	<React.Fragment>
			      <div className="cartScreenBody">
			      	<div className="cartScreenBody__wrapper">
			      		<div className="checkoutPM">
			      				{ formPaymentError ?
										<React.Fragment>
											<div style={{padding: '15px 20px', background: serverMessageType === 'success' ? '#46be8a' : '#fb434a', borderRadius: '5px', marginBottom: '20px', color: '#fff', 'fontWeight': 500}}>
					              {formPaymentError}
					            </div>
									  </React.Fragment>
				          : null }
			      			<div className="checkoutPM__head">Payment method</div>
			      			<ul className="checkoutPM__list">
			      				{this.renderPaymentMethods()}
			      			</ul>
			      		</div>
			      	</div>
			      </div>
			      <div className="appBody__bottom">
			      	<div className="cartScreenFooter">
			        	<div className="cartScreenTotal">
			        		<div className="cartScreenTotal__item">
			        			Order total
			        			<span className="cartScreenTotal__price cartScreenTotal__price--red">${!!orderTotal?orderTotal.toFixed(2):''}{payment_processing_by_client === 1 && methodLabel === 'card'?`*`:null}</span>
			        		</div>
									{payment_processing_by_client === 1 && methodLabel === 'card'?
										<>
										<span className="cartScreenTotal__price">*Payment processing fee will be applied on the next step</span>
										<span className="cartScreenTotal__price">Domestic cards: {this.caluclateCardFee(orderTotal).domestic}</span>
										<span className="cartScreenTotal__price">International fee: {this.caluclateCardFee(orderTotal).international}</span>
										</>
										: null}
			        	</div>
			        	{ !methodLabel ? <Button className="cartScreenFooter__checkout" type="button" onClick={this.noMethodSelected}>
			            Submit order
			          </Button> : null }
			          { ['cash', 'card_pay_at_counter', 'pay_at_counter'].indexOf(methodLabel) > -1 ? <Button type="button" onClick={this.offlineMethod}>
			            Submit order
			          </Button> : null }
			          { methodLabel === 'card' ?
								<Button type="button" onClick={this.payWithCard}>
			            Submit order
			          </Button>
								: null }
			        </div>
			      </div>
			      </React.Fragment>
	      : null }
			</div>
		);
  }
}

const PaymentWrapper = (props) => {
	const stripe = useStripe();
	const elements = useElements();
	const [formPaymentError, setFormPaymentError] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [cartError, setCartError] = useState('');
	const { orderUuid, branchUrl } = props.match.params;

	const showError = (msg, type = 'error') => {

		setFormPaymentError(msg);
  	setTimeout(() => {
			setFormPaymentError('');
		}, 3000);
  }

	const goToDone = () => {
		setTimeout(() => {
			props.history.push(`/${branchUrl}/orders/${orderUuid}/receipt`);
		}, 2000);
	}
	const submitForm = async (event = false) => {
		console.log('submitForm');
		if (event) event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}

		Analytics('PaymentStart')

		setFormPaymentError('');
		setIsSubmitting(true);

		// Get a reference to a mounted CardElement. Elements knows how
		// to find your CardElement because there can only ever be one of
		// each type of element.
		const cardElement = elements.getElement(CardElement);

		// Use your card Element with other Stripe.js APIs
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
		});

		if (error) {
			console.log('[error]', error);
			setCartError(error.message);
			setIsSubmitting(false);
			return;
		}


		console.log('[PaymentMethod]', paymentMethod);
		const body = {
			orderUuid,
			paymentMethod
		};

		console.log('body', body);

		fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/checkout/pay', {
			method: 'post',
			body
		}).then(async(response) => {
			console.log(response);

			if (response.code === 200) {
				console.log('response.message', response.message);

				const res = await stripe.confirmCardPayment(response.data.secret, {
					payment_method: paymentMethod.id
				});

				console.log('Payment result is', res);

				if (res.paymentIntent && res.paymentIntent.status === 'succeeded') {
					console.log("DONE ITS PAID");
					Analytics('CoursePurchase');

					goToDone();
				} else if (res.error) {
					setFormPaymentError(res.error.message);
					setIsSubmitting(false);
				}
			} else if (response.code === 200) {
				goToDone();
			} else {
				//this.changeFailMessage(data.message);
				setFormPaymentError(response.message);
				setIsSubmitting(false);
			}
		}).catch(error => {
			const { t } = props;
			console.log(error);
			setFormPaymentError('Server error catch');
			Analytics('ServerError')

			showError(t('payInvoice.server_error', { defaultValue: 'Server error catch' }));
		});
	}

	useEffect(() => {
		return () => {
			const frames = Object.keys(stripe._controller._frames);
			if (frames && Array.isArray(frames) && frames.length) {
				frames.forEach((frameId) => {
					 if (stripe._controller._frames[frameId]) {
						// Stop all listeners, remove iframes
						stripe._controller._frames[frameId]._iframe.remove();
						stripe._controller._frames[frameId]._removeAllListeners();
						stripe._controller._controllerFrame._removeAllListeners();
						stripe._controller._controllerFrame._iframe.remove();
					}
				})
			}
		}
	}, []);// eslint-disable-line

	return (
		<Checkout
			stripe={stripe}
			elements={elements}
			formPaymentError={formPaymentError}
			cartError={cartError}
			setCartError={setCartError}
			submitForm={submitForm}
			isSubmitting={isSubmitting}
			showError={showError}
			{ ...props }
		/>
	);
}



const App = (props) => {
	const [stripePromise, setStripePromise] = useState(false);

	const { orderUuid } = props.match.params;

	useEffect(() => {
		async function getStripe(stripe_account_id = null) {
			const { loadStripe } = require('@stripe/stripe-js');

			let stripeObj = {};
			if (stripe_account_id) {
				stripeObj.stripeAccount = stripe_account_id;
			}
			console.log('stripeObj' ,stripeObj)

			const stripe = await loadStripe(Settings.stripePublicKey, stripeObj);
			setStripePromise(stripe);
		}



		fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/checkout/getOrder/', {
      method: 'POST',
      body: {
      	orderUuid
      }
    }).then(response => {
    	const payment_in_app_value = _.get(response, 'data.branch.payment_in_app')
    	const stripe_charges_enabled = _.get(response, 'data.branch.stripe_charges_enabled');
    	const stripe_account_id = _.get(response, 'data.branch.stripe_account_id');

    	let payment_in_app = false;
    	if (payment_in_app_value && stripe_charges_enabled) {
    		payment_in_app = true;
    	}

    	console.log('payment_in_app', payment_in_app);

      getStripe(stripe_account_id);
   	})
   	.catch(error => {
			console.log(error);
		});

	}, [orderUuid]);

	const ELEMENTS_OPTIONS = {};

	return (
		<div className="AppWrapper">
			{ stripePromise ? <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
				<PaymentWrapper {...props} />
			</Elements> : null }
		</div>
	);
};

export default withTranslation()(withWindowSizeListener(App));
