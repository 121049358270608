/**
 * fetchJSON use to communicate with the server
 * 
 */
import fetch from 'isomorphic-fetch';
import i18n from 'i18next';
import Auth from './Auth';
import { getUniqueKey } from 'services/utils/Analytics';

function checkStatus(response) {
  if(response.ok) {
    return response;
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

function parseJSON(response) {
  return response.json();
}

export default function enhancedFetch(url, options) {
  options.headers = Object.assign({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Api-Language': i18n.language || 'en',
    'UserKey': getUniqueKey(),
    'UserFinger': window.fingerprint
  }, options.headers);
  // console.log('options.headers', options.headers);
  if (Auth.getToken()) {
    options.headers.Authorization = `bearer ${Auth.getToken()}`;
  }

  if (typeof options.body !== 'string') {
    options.body = JSON.stringify(options.body);
  }

  // console.log(options);
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON);
}