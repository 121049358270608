import { SET_DATA, SWITCH_SHOW } from "../../types/MenuTypes";

const initialState = {
  wasShowed: false,
  surcharges: 1,
  surcharges_comment: '',
  isLoading: true
}
export function MenuReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        isLoaded : true,
        surcharges: action.payload.surcharges,
        surcharges_comment: action.payload.surcharges_comment,
        isLoading: action.payload.isLoading
      }
    case SWITCH_SHOW:
      console.log(action)
      return {
        ...state,
        wasShowed: action.payload
      }
    default:
      return state;
  }
}
