import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

Modal.setAppElement('#app')

const ReadyPay = (props) => {

  const afterOpenModal = () => {
    console.log('afterOpenModal');
  }

    const {
    activeReadyPayModal,
    branchUrl
  } = props;

  const closeModal = () => {
    props.history.push(`/${branchUrl}/`);
  }

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      borderRadius: '20px',
      border: 0,
      overflow: 'unset',
      padding: 0
    }
  };

  return (
    <Modal
      isOpen={activeReadyPayModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
    >
    <div className="readyPayModal">
      <div className="readyPayModal__container">
        <div className="readyPayModal__text">Please proceed to the counter when you are ready to pay/leave the venue.</div>
        <button className="readyPayModal__close" onClick={closeModal}></button>
         <Link
          className="appBody__bottomBtn"
          style={{display: 'block'}}
          to={`/${branchUrl}/`}>Ok</Link>
      </div>
    </div>
    </Modal>
  );
};

export default ReadyPay;


