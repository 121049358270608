import React from 'react';
import Settings from 'services/config/Settings';
import { withWindowSizeListener } from 'react-window-size-listener';
import fetchJSON from 'services/utils/fetchJSON';
import { Link } from 'react-router-dom';
import Button from 'components/Button/Button';
import qs from 'qs';
import _ from 'lodash';
import Auth from 'services/utils/Auth';
import { ReactComponent as IconCloseProduct } from 'components/images/icon-close-single-product.svg';

class orderType extends React.Component {
	state = {
		branchUuid: '',
		order_types: [{
      label: 'dine_in',
      viewLabel: 'Dine In'
    },
    {
      label: 'takeout',
      viewLabel: 'Take Out'
    }],
    activeType: 'dine_in',
    table_id: '',
    tablesList: [],
    dine_inActive: 1,
    takeoutActive: 0,
    take_out: 0,
    snooze: 0,
    guest_mode: 0,
    branchName: '',
		orderHasAlcohol: false
	}
	componentDidMount = () => {
		document.title = `Order | ${Settings.title}`;
		const { orderUuid } = this.props.match.params;
		this.getOrder(orderUuid);
  }

  isOrderHesAlcohol = (orderItems) => {
		for (const item of orderItems) {
			if (item?.product?.is_alcohol === true) {
				return true
			}
		}
		return false
	}

  getOrder = (orderUuid) => {
  	this.setIsFetching(true);
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/checkout/getOrder/', {
      method: 'POST',
      body: {
      	orderUuid
      }
    }).then(response => {
      console.log(response);
      this.setIsFetching(false);
      if (response.code === 200) {
      	const { order, tables } = response.data;

        const { role } = this.props;

        const takeoutActive = _.get(response, 'data.branch.take_out');
        let take_out = _.get(response, 'data.branch.take_out');

        if (role === 'guest') {
          take_out = 0;
        }

      	this.setState({
      		orderTotal: order.total,
      		branchUuid: response.data.branchUuid,
      		branchName: response.data.branchName,
      		is_paid: order.is_paid,
      		order: order,
      		tablesList: tables,
      		table_id: order.table_id || '',
      		activeType: order.order_type || 'dine_in',
          takeoutActive,
          take_out ,
          snooze: _.get(response, 'data.branch.snooze'),
          guest_mode: role === 'guest' ? 1 : 0,
					payment_processing_by_client: order.payment_processing_by_client,
					orderHasAlcohol: this.isOrderHesAlcohol(order?.order_items)
      	});



      	if (order.is_paid) {
          const { branchUrl } = this.props.match.params;

      		this.props.history.push(`/${branchUrl}/orders/${orderUuid}`);
      		return;
      	}

      	let redirectTable = true;
      	if (window.location.search) {
		      const param = window.location.search;
		      const paramFromUrl = qs.parse(param, { ignoreQueryPrefix: true });
		      const noRedirect = paramFromUrl.r ? paramFromUrl.r : null;
		      if (noRedirect) {
		        redirectTable = false;
		      }
		    }

      	if (order.table_id && redirectTable) {
          const { branchUrl } = this.props.match.params;
          if (Auth.isUserAuthenticated()) {
            this.props.history.push(`/${branchUrl}/checkout/${orderUuid}/payment`);
          } else {
            this.props.history.push(`/${branchUrl}/signup?order=${orderUuid}`);
          }
      	}
      }
     }).catch(error => {
     	console.log(error);
     });
  }
  setIsFetching = (param) => {
  	this.setState({
  		isFetching: param
  	})
  }
  showError = (msg, type = 'error') => {
  	this.setState({
  		serverMessage: msg,
  		serverMessageType: type
  	}, () => {
  		setTimeout(() => {
  			this.setState({
		  		serverMessage: '',
		  		serverMessageType: ''
		  	})
  		}, 3000);
  	});
  }
  goForward = () => {
  	const { activeType, table_id } = this.state;
  	console.log('table_id', table_id, 'activeType', activeType);

  	if (activeType === 'dine_in' && !table_id) {
  		this.showError('Please, select your table number');
  		return;
  	}

  	const { orderUuid, branchUrl } = this.props.match.params;

    if (Auth.isUserAuthenticated()) {
      this.props.history.push(`/${branchUrl}/checkout/${orderUuid}/payment`);
    } else {
      this.props.history.push(`/${branchUrl}/signup?order=${orderUuid}`);
    }
  }
  onTypeChange = (method) => {
  	this.setState({
    	activeType: method.label
    }, () => {
	  	this.orderChanged();
    });
  }
  orderChanged = () => {
  	const { orderUuid } = this.props.match.params;
  	const { activeType, table_id } = this.state;
  	this.setIsFetching(true);

  	fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/checkout/changeOrder/', {
      method: 'POST',
      body: {
      	orderUuid,
      	order_type: activeType,
      	table_id
      }
    }).then(response => {
      console.log(response);
      this.setIsFetching(false);
    }).catch(error => {
     	console.log(error);
    });
  }
  changeTableId = (e) => {
  	this.setState({
  		table_id: e.target.value
  	}, this.orderChanged);
  }
  renderOrderTypes = () => {
  	const {
      order_types,
      activeType,
      table_id,
      tablesList,
      take_out,
      snooze,
      branchName,
      guest_mode,
			orderHasAlcohol
    } = this.state;

    if (order_types.length) {
      return order_types.map((method, key) => {
        let visible = true;
        const stateName = method.label+'Active';
        visible = this.state[stateName];
        let disabled = false;

        if (method.label === 'takeout' && take_out !== 1) {
          disabled = true;
        }
        if (method.label === 'takeout' && orderHasAlcohol === true && this.props?.branchObj?.is_alcohol_sales_for_takeaway === false) {
        	disabled = true
				}

        return (<li className={"checkoutPM__item checkoutPM__methods "+(visible ? 'active' : 'hide')} key={key}>
            <input
              id={`paymentMethod-${method.label}`}
              className="checkoutPM__input"
              type="radio"
              name="paymentMethod"
              value={ method.label }
              checked={ activeType === method.label }
              onChange={ e => this.onTypeChange(method) }
              disabled={ disabled }
            />
            <label htmlFor={`paymentMethod-${method.label}`} className="checkoutPM__label">
              <span className={"checkoutPM__title paymentTitle "}>{method.viewLabel}</span>
            </label>
            { disabled && method.label === 'takeout' && snooze ?
              <div style={{
                padding: '15px 20px',
                background: '#fff3cd',
                borderRadius: '5px',
                marginBottom: '20px',
                color: '#856404',
                fontWeight: 500,
                margin: '7px',
                fontSize: '15px'
              }}>
                Sorry, {branchName} restaurant is really busy for take out orders! Try again in {snooze}.
              </div>
            : null }
						{ disabled && method.label === 'takeout' && orderHasAlcohol === true && this.props?.branchObj?.is_alcohol_sales_for_takeaway === false ?
              <div style={{
                padding: '15px 20px',
                background: '#fff3cd',
                borderRadius: '5px',
                marginBottom: '20px',
                color: '#856404',
                fontWeight: 500,
                margin: '7px',
                fontSize: '15px'
              }}>
                Sorry, Alcohol is not available for takeout.
              </div>
            : null }
            { disabled && method.label === 'takeout' && guest_mode ?
              <div style={{
                padding: '15px 20px',
                background: '#fff3cd',
                borderRadius: '5px',
                marginBottom: '20px',
                color: '#856404',
                fontWeight: 500,
                margin: '7px',
                fontSize: '15px'
              }}>
                Take out is not available in a Guest mode. To enable it please, logout and create an account.
              </div>
            : null }
            { //
            	method.label === 'dine_in' ?
            	<div className={"sbCardForm "+(activeType === 'dine_in' && method.label === 'dine_in' ? 'visible' : 'hide')}>
            		<div className="checkoutTable">
            			<label htmlFor="tableNumber" className="checkoutTable__label">Table number</label>
                  <select
                    className="fillInForm__itemSelect"
                    type="text"
                    name="tableNumber"
                    onChange={this.changeTableId}
                    value={table_id}
                    style={{ width: '100%' }}
                  >
                    {!table_id ? <option>Choose table number</option> : null }
                    {tablesList.map((item) => <option value={item.id} key={item.id}>{item.display_name}</option>)}
                  </select>
            		</div>
            	</div>
            	: null
            }
          </li>
        );
      })
    }

    return null;
  }
  render() {
  	const {
  		branchUuid,
  		orderTotal,
  		serverMessage,
  		serverMessageType,
  		paymentFetching,
  		isFetching,
			payment_processing_by_client
  		// order
  	} = this.state;

  	const containerStyle = {
	    width: this.props.windowSize.windowWidth,
	    height: this.props.windowSize.windowHeight,
	    minHeight: this.props.windowSize.windowHeight,
	  };
    const { branchUrl } = this.props.match.params;

	  return(
			<div id="appContainer" className="app-container" style={containerStyle} data-animation-spinning={paymentFetching || isFetching}>
				<div className="cartScreen__top">
					<div className="checkout__container">
						<div className="cartScreen__top cartScreen__top--50">

			        <div className="cartScreenHeader">
			        	<div className="menu__container">
			        		<div className="cartScreenHeader__inner">
				        		{ branchUuid && <Link to={`/${branchUrl}/`} className="cartScreenHeader__back">
                      <IconCloseProduct className="cartScreenHeader__back--icon" />
                      Back to menu
                    </Link> }

			        		</div>
			        	</div>
			        </div>
			      </div>
		      </div>
	      </div>
	      	<React.Fragment>
			      <div className="cartScreenBody">
			      	<div className="cartScreenBody__wrapper">
			      		<div className="checkoutPM">
			      				{ serverMessage ?
										<React.Fragment>
											<div style={{padding: '15px 20px', background: serverMessageType === 'success' ? '#46be8a' : '#fb434a', borderRadius: '5px', marginBottom: '20px', color: '#fff', 'fontWeight': 500}}>
					              {serverMessage}
					            </div>
									  </React.Fragment>
				          : null }
			      			<div className="checkoutPM__head">Order type</div>
			      			<ul className="checkoutPM__list">
			      				{this.renderOrderTypes()}
			      			</ul>
			      		</div>
			      	</div>
			      </div>
			      <div className="appBody__bottom">
			      	<div className="cartScreenFooter">
			        	<div className="cartScreenTotal">
			        		<div className="cartScreenTotal__item">
			        			Order total
			        			<span className="cartScreenTotal__price cartScreenTotal__price--red">${!!orderTotal?orderTotal.toFixed(2):''}</span>
			        		</div>
			        	</div>
			        	<Button type="button" onClick={this.goForward}>
			            Continue
			          </Button>
			        </div>
			      </div>
			      </React.Fragment>
			</div>
		);
  }
};

export default withWindowSizeListener(orderType);
