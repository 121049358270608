import React from 'react';
import Header from 'components/Header/Header';

import './styles.less';

class AppWrapper extends React.Component {
  render() {
    return (
      <React.Fragment>
    		<Header {...this.props} />
    		{ this.props.children }
  		</React.Fragment> 
    )
  }
};

export default AppWrapper;