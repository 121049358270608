import React from 'react';
import Cookies from 'js-cookie';
import Analytics from 'services/utils/Analytics';
import SlideMenu from './components/SlideMenu';
import Auth from 'services/utils/Auth';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'components/images/dezy-logo-only.svg';
import { ReactComponent as BurgerIcon } from './components/images/menu-closed.svg';

import './components/styles.less';
import surchargesLogoMessageString from '../../helpers/surchargesLogoMessageString'
import Button from "../Button/Button";
import Modal from "react-modal";

class Header extends React.Component {
	constructor(props) {
    super(props);
		this.state = {
			isOpen: false,
			appLanguage: 'en',
			branchId: false,
			showSurchargeModel: true
		}
		const customStyles = {
			content: {
				top: '50%',
				left: '50%',
				right: 'auto',
				bottom: 'auto',
				marginRight: '-50%',
				transform: 'translate(-50%, -50%)',
			},
		}
	}
	componentDidMount = () => {
		const { branchId } = this.props.match.params;
		const appLanguage = Cookies.get('app_language');
		this.setState({
			appLanguage,
			branchId
		});
  }
	openMenu = (e) => {
		const isOpen = this.state.isOpen ? false : true;
		const status = isOpen ? 'Open menu' : 'Close menu';

		Analytics('Clicked on the menu - ' + status);
		this.setState({
			isOpen: isOpen
		});
	}
	handleClose = () => {
		setTimeout(() => {
			this.setState({
				isOpen: false
			})
		}, 50);
	}

	//
	//<img src={ Logo }  />
  render() {
  	const isUserAuthenticated = Auth.isUserAuthenticated();
  	const { branchUrl } = this.props.match.params;
  	const { showSurchargeModel } = this.state;

     return (

      <div className="headerWrapper">
				<div className="headerMenu">
					<div className="headerMenu__container">
						<div className="headerMenu__logo" style={{display: "flex", alignItems: "center"}}>
							<a className="headerMenu__link" style={{marginRight: "20px"}}  href={branchUrl ? `/${branchUrl}/` : '/'}>
								<Logo alt="dezy logo"  className="menu__logoImg" />
							</a>
						</div>
						{
							isUserAuthenticated ?
							<div className="headerMenu__burger">
								<button
									className="headerMenu__burger-btn"
									type="button"
									onClick = { this.openMenu }
								 ><BurgerIcon className="headerMenu__burger-btn--icon" /></button>
							 </div>
							:
							<div className="headerMenu__login" style={{ 'display': 'none' }}>
								<Link to="/login" className="headerMenu__loginLink">Log in</Link>
							</div>
						}
					</div>
				</div>
				<React.Fragment>
					<div className={"slideMenu__backdrop" + ( this.state.isOpen ? " opened" : " closed" )}></div>
					<SlideMenu
						handleClose={this.handleClose}
						isOpen={this.state.isOpen}
						{ ...this.props }
						/>
				</React.Fragment>
			</div>
    )
  }
};

export default Header;
