import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import store from "./redux/store";

import Routes from 'services/config/Routes';

// styles
import 'normalize.css';
import './App.less';
import './i18n';

ReactDOM.render(
    <Suspense fallback={null}>
        <Provider store={store}>
            <Routes />
        </Provider>
    </Suspense>,
    document.getElementById('app')
);
