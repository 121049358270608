import React from 'react';
import Settings from 'services/config/Settings';
import { withWindowSizeListener } from 'react-window-size-listener';


class Help extends React.Component {
	componentDidMount = () => {
		document.title = `Help | ${Settings.title}`;
  }

  render() {
  	const containerStyle = {
	    width: this.props.windowSize.windowWidth,
	    height: this.props.windowSize.windowHeight,
	    minHeight: this.props.windowSize.windowHeight
	  };

    return (
      <div className="mainPage" style={containerStyle}>
      	Help page
      </div>
    )
  }
};

export default withWindowSizeListener(Help);