import React, { useEffect, useState } from 'react';
import { parse, format, isValidNumber } from 'libphonenumber-js'
import { withWindowSizeListener } from 'react-window-size-listener';
import Settings from 'services/config/Settings';
import Auth from 'services/utils/Auth';
import fetchJSON from 'services/utils/fetchJSON';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { ReactComponent as Logo } from 'components/images/dezy-header-logo-v2.svg';
import Button from 'components/Button/Button';
import qs from 'qs';
import moment from 'moment-timezone';
import _ from 'lodash';

const SignUp = (props) => {
  // Then inside the component body
  const { t } = useTranslation();

  const { branchUrl } = props.match.params;

  const [orderUuid, setOrderUuid] = useState(null);
  const [guestMode, setGuestMode] = useState(false);
  const [stage, setStage] = useState(1);
  const [timeLeft, setTimeLeft] = useState(null);
  const [isActiveLogin, setSwitchLogin] = useState(false);
  const [defaultValues, setDataFromServer] = useState({
    email: '',
    phone: '',
    full_name: ''
  });

  const componentDidMount = () => {
    document.title = `${t('signUp.documentTitle', { defaultValue: 'Sign up' })} | ${Settings.title}`;

    if (window.location.search) {
      const param = window.location.search;
      const paramFromUrl = qs.parse(param, { ignoreQueryPrefix: true });
      const orderId = paramFromUrl.order ? paramFromUrl.order : null;
      if (orderId) {
        getOrder(orderId);
        getCovidData(orderId);
      }
    }
  }
  useEffect(componentDidMount, []);

  const getOrder = (orderId) => {
    setOrderUuid(orderId);

    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/checkout/getOrder/', {
      method: 'POST',
      body: {
        orderUuid: orderId
      }
    }).then(response => {
      const order_type = _.get(response, 'data.order.order_type');
      if (order_type === 'dine_in') {
        setGuestMode(true);
      }
    })
      .catch(error => {
        console.log(error);
      });
  }
  const preventDefault = (e) => {
    e.preventDefault();
  }
  // remove scrolling jumping feature on iphones
  useEffect(() => {
    document.body.addEventListener('touchmove', preventDefault, { passive: false });
    // returned function will be called on component unmount 
    return () => {
      document.body.removeEventListener('touchmove', preventDefault)
    }
  }, []);

  const containerStyle = {
    width: props.windowSize.windowWidth,
    height: props.windowSize.windowHeight,
    minHeight: props.windowSize.windowHeight
  };


  const getCovidData = (orderId) => {
    console.log('getCovidData');
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/auth/covid/${orderId}`, {
      method: 'GET'
    }).then(response => {
      console.log(response);
      if (response.code === 200) {
        if (response.data) {
          setDataFromServer({
            email: response.data.email,
            phone: response.data.phone,
            full_name: response.data.name
          });
        }
      }

    }).catch(error => {
      console.log(error);
    });
  };

  const getData = () => {
    return {
      email: defaultValues.email,
      phone: defaultValues.phone,
      full_name: defaultValues.full_name,
      terms: false,
      code: ''
    };
  }
  const validate = values => {
    const errors = {};
    const requiredText = t('shareable.required', { defaultValue: 'Required' });

    if (stage === 1) {
      if (!values.email) {
        errors.email = requiredText;
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = t('shareable.invalidEmail', { defaultValue: 'Invalid email address' });
      }

      if (!values.full_name) {
        errors.full_name = requiredText;
      }
      if (!values.phone) {
        errors.phone = requiredText;
      }
      if (!values.terms) {
        errors.terms = requiredText;
      }
    }
    if (stage === 2) {
      if (!values.code) {
        errors.code = requiredText;
      }
    }

    return errors;
  };
  const proccessNumber = (value) => {
    console.log('phonePlain', value);

    const validNumberUS = isValidNumber(value, 'US');
    const validNumberCY = isValidNumber(value, 'CY');
    const validNumberAU = isValidNumber(value, 'AU');

    let parseNumber = parse(value, 'AU');
    let formatNumber = format(parseNumber.phone, 'AU', 'International_plaintext');

    if (!validNumberAU) {
      if (validNumberCY) {
        parseNumber = parse(value, 'CY');
        formatNumber = format(parseNumber.phone, 'CY', 'International_plaintext');
      } else if (validNumberUS) {
        parseNumber = parse(value, 'US');
        formatNumber = format(parseNumber.phone, 'US', 'International_plaintext');
      } else {
        formatNumber = value;
      }
    }
    console.log('formatNumber', formatNumber);
    return formatNumber;
  }
  const onSubmit = (values, { setSubmitting, resetForm, setErrors }) => {
    // alert(JSON.stringify(values, null, 2));

    // get user's timezone 
    values.timezone = moment.tz.guess();
    values.stage = stage;
    values.phoneNumber = proccessNumber(values.phone);

    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/auth/signup', {
      method: 'POST',
      body: values
    }).then(response => {
      console.log(response);
      setSubmitting(false);

      if (response.code === 202) {
        if (response.data.stage) {
          setStage(response.data.stage);
          if (response.data.stage === 2) {
            setTimeLeft(60);
          }
        }
      }

      if (response.code === 200) {
        // If login was successful, set the token in cookie
        Auth.loginUser(response.token);
        if (orderUuid) {
          props.history.push(`/${branchUrl}/checkout/${orderUuid}/payment`);
        } else {
          props.history.push(`/${branchUrl}/`);
        }
      } else {
        let errors = {};
        if (response.field) {
          errors[response.field] = response.message;
        }
        if (response.errors) {
          errors = response.errors;
        }
        setErrors(errors);
      }
    }).catch(error => {
      console.log(error);
      setSubmitting(false);
      const errors = {
        phone: 'Something went wrong, please repeat',
        code: 'Something went wrong, please repeat'
      };
      setErrors(errors);
    });
  };

  const continueAsGuest = () => {
    const values = {};
    values.timezone = moment.tz.guess();

    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/auth/guest', {
      method: 'POST',
      body: values
    }).then(response => {
      console.log(response);
      //setSubmitting(false);

      if (response.code === 200) {
        // If login was successful, set the token in cookie
        Auth.loginUser(response.token);
        if (orderUuid) {
          props.history.push(`/${branchUrl}/checkout/${orderUuid}/payment`);
        } else {
          props.history.push(`/${branchUrl}/`);
        }
      } else {
        let errors = {};
        if (response.field) {
          errors[response.field] = response.message;
        }
        if (response.errors) {
          errors = response.errors;
        }
        //setErrors(errors);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const goToLogin = () => {
    isActiveLogin ? props.history.push(`/${branchUrl}/login?order=${orderUuid}`) : setSwitchLogin(!isActiveLogin)
  }

  useEffect(() => {
    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      if (timeLeft) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={getData()}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, handleBlur, setSubmitting }) => {
        const errorField = (fieldKey) => {
          return errors[fieldKey] && touched[fieldKey] && <small className="fillInForm__itemError">{errors[fieldKey]}</small>
        }
        const formItemClass = (fieldKey = null) => {
          return `fillInForm__item ${(errors[fieldKey] && touched[fieldKey]) ? 'withError' : ''}`;
        }
        const sendCodeAgain = () => {
          setSubmitting(true);
          values.timezone = moment.tz.guess();
          values.stage = 1;

          fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/auth/signup', {
            method: 'POST',
            body: values
          }).then(response => {
            console.log(response);
            setSubmitting(false);
          }).catch(error => {
            console.log(error);
            setSubmitting(false);
          });
        }

        return (
          <form onSubmit={handleSubmit} className="app-container" style={containerStyle}>
            <div className="appBody__top">
              <div className="appBody__container">
                <div className="appBody__wrapper">
                  <div className="fillIn">
                    <div className="fillIn__logo">
                      <Logo alt="dezy logo" className="fillIn__logoImg" />
                    </div>
                    {
                      isActiveLogin
                        ?
                        <div className="fillInForm" style={{
                          display: stage !== 1 && 'none'
                        }}>
                          <div className={formItemClass('full_name')}>
                            <label htmlFor="full_name"><Trans i18nKey="shareable.fullName">Full name</Trans></label>
                            <input
                              className="fillInForm__itemInput"
                              type="text"
                              name="full_name"
                              placeholder={t('shareable.fullName', { defaultValue: 'Full name' })}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.full_name}
                            />
                            {errorField('full_name')}
                          </div>

                          <div className={formItemClass('email')}>
                            <label htmlFor="email">{t('shareable.email', { defaultValue: 'Email' })}</label>
                            <input
                              className="fillInForm__itemInput"
                              type="email"
                              name="email"
                              placeholder={t('shareable.email', { defaultValue: 'Email' })}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            {errorField('email')}
                          </div>

                          <div className={formItemClass('phone')}>
                            <label htmlFor="phone">{t('shareable.phoneNumber', { defaultValue: 'Phone number' })}</label>
                            <input
                              className="fillInForm__itemInput"
                              type="tel"
                              name="phone"
                              placeholder={t('shareable.phoneNumber', { defaultValue: 'Phone number' })}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phone}
                            />
                            {errorField('phone')}
                          </div>
                          <div className={formItemClass('terms')}>
                            <div className="fillIn__terms">
                              <input
                                id="termsAgree"
                                type="checkbox"
                                name="terms"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.terms}
                                className="fillIn__checkbox-input"
                              />
                              <label className="fillIn__terms-text notHide" htmlFor="termsAgree">
                                <span>
                                  <span className="fillIn__checkbox-icon"></span>
                                  I agree with <Link to="/terms-conditions" className="fillIn__terms-link" target="_blank">Terms & Conditions</Link> and <Link to="/privacy-policy" className="fillIn__terms-link" target="_blank">Privacy Policy</Link>
                                </span>
                              </label>
                              {errorField('terms')}
                            </div>
                          </div>
                        </div>
                        : null
                    }
                    <div className="fillInForm" style={{
                      display: stage !== 2 && 'none'
                    }}>
                      <div className={formItemClass('code')}>
                        <label htmlFor="code"><Trans i18nKey="shareable.code">Code</Trans></label>
                        <input
                          className="fillInForm__itemInput"
                          type="text"
                          name="code"
                          placeholder={t('shareable.code', { defaultValue: 'Code' })}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.code}
                        />
                        {errorField('code')}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="appBody__bottom">
              <div className="appBody__bottomContainer">
                {stage === 1 && guestMode && <div style={{ paddingBottom: '20px' }}>
                  <button className="fillIn__footerLink" type="button" onClick={continueAsGuest}>Continue as a guest</button>
                </div>}
                {stage === 1 && <div style={{ paddingBottom: '20px' }}>
                  <Button type="button" addClass="inversion-colors" disabled={isSubmitting} onClick={() => props.history.push(`/${branchUrl}/login?order=${orderUuid}`)} style={{fontSize: '18px' }}>
                    I already have an account
                  </Button>
                </div>}
                {stage === 2 && <div style={{ paddingBottom: '20px' }}>
                  <button
                    className="fillIn__footerLink"
                    type="button"
                    onClick={sendCodeAgain}
                    disabled={timeLeft !== 0}
                  >Send the code again {timeLeft ? `in ${timeLeft} sec` : ''}</button>
                </div>}

                <Button type="submit" disabled={isSubmitting} onClick={() => isActiveLogin ? null : setSwitchLogin(!isActiveLogin)}>
                  {stage === 1 && <Trans i18nKey="signUp.submitButton">Sign Up</Trans>}
                  {stage === 2 && <Trans i18nKey="shareable.submit">Submit</Trans>}
                </Button>
              </div>
            </div>
          </form>
        )
      }}
    </Formik>
  );
};


export default withWindowSizeListener(SignUp);

