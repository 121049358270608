import React from 'react';
import Settings from 'services/config/Settings';
import { withWindowSizeListener } from 'react-window-size-listener';
import fetchJSON from 'services/utils/fetchJSON';
import moment from 'moment-timezone';
import Button from 'components/Button/Button';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import ReadyPay from './components/ReadyPay';
import {surchargesToString} from "../../helpers/surchargesToString";
import {correctModifierSurcharges} from "../../helpers/correctModifierSurcharges";

// import MenuTop from 'components/MenuTop/MenuTop';

class SingleOrders extends React.Component {
  state = {
    order: {
      id: '',
      order_items: [],
      transaction: {}
    },
    isFetching: true,
    tables: [],
    branch: {},
    activeReadyPayModal: false,
    menu_id: null
  }
	componentDidMount = () => {
		document.title = `MyOrders | ${Settings.title}`;
    this.getOrder();
  }
  getOrder = () => {
    const { orderUuid } = this.props.match.params;
    this.setIsFetching(true);
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/checkout/getOrder/', {
      method: 'POST',
      body: {
        orderUuid
      }
    }).then(response => {
      console.log(response);
      this.setIsFetching(false);
      if (response.code === 200) {
        const { order, timezone } = response.data;
        const { order_type } = order;

        if (order_type === 'takeout') {
          if (order.submitted_at) {
            order.submitted_at = moment.utc(order.submitted_at);
            if (order.take_out_delay) {
              order.submitted_at.add(order.take_out_delay, 'minutes')
            }
            if (order.serving_time) {
              order.submitted_at.add(order.serving_time, 'minutes')
            }
            order.submitted_at = order.submitted_at.tz(timezone).format('MM/DD/YY h:mm a');
          }
        } else {
          order.submitted_at = order.submitted_at ? moment.utc(order.submitted_at).tz(timezone).format('MM/DD/YY h:mm a') : false;
        }
        this.setState({
          orderTotal: response.data.order.total,
          menu_id: response.data.order.menu_id,
          branchUuid: response.data.branchUuid,
          branchName: response.data.branchName,
          is_paid: response.data.order.is_paid,
          order: response.data.order,
          tables: response.data.tables,
          branch: response.data.branch,
          surcharges: response.data.surcharges,
          surcharges_amount: response.data.surcharges_amount,
          discount_amount: response.data.discount_amount,
          discount_value: response.data.discount_value
        }, this.proccessOrder);
      }
     }).catch(error => {
        console.log(error);
     });
  }
  proccessOrder = () => {
    // const { order } = this.state;
  }
  readyToPay = () => {
    if (window.confirm("Are you sure?")) {
      const { orderUuid } = this.props.match.params;
      const { branchUuid } = this.state;
      fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/checkout/readyPay/', {
        method: 'POST',
        body: {
          orderUuid,
          branchUuid
        }
      }).then(response => {
        console.log(response);
        if (response.code === 200) {
          this.setState({
            activeReadyPayModal: true
          });
          this.getOrder();
        }
       }).catch(error => {
          console.log(error);
       });
    }
  }
  renderStatus = () => {
    const { order } = this.state;
    const render = [];
    const { order_type } = order;

    if (!order.is_refunded && !order.is_canceled) {
      if (order.is_paid) {
        render.push(<div className="checkoutPaidCardStatus__tag paid" key="paid">Paid</div>)
      } else {
        render.push(<div className="checkoutPaidCardStatus__tag notpaid" key="notpaid">Not Paid</div>)
      }
    }

    if (order.is_refunded) {
      render.push(<div className="checkoutPaidCardStatus__tag notpaid" key="Refunded">Refunded</div>)
    }

    if (order.is_canceled) {
      render.push(<div className="checkoutPaidCardStatus__tag notpaid" key="is_canceled">Canceled</div>)
    }

    if (order.is_delivered && order_type === 'dine_in') {
      render.push(<div className="checkoutPaidCardStatus__tag delivered" key="delivered">Delivered</div>)
    }


    return render;
  }

  calculateTotal = (total, fee) => (total + fee).toFixed(2)

  reOrder = () => {
    const { branchUrl, orderUuid } = this.props.match.params;
    const {
      menu_id,
    } = this.state;

    this.setIsFetching(true);

    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/orders/resume/', {
      method: 'POST',
      body: {
        branchUuid: branchUrl,
        menuId: menu_id,
        tableId: null
      }
    }).then(response => {
      console.log(response);
      this.setIsFetching(false);
      if (response.code === 200) {
        console.log('response.data.order_uuid', response.data.order_uuid);

        fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/orders/copyOver/', {
          method: 'POST',
          body: {
            branchUrl: branchUrl,
            orderFrom: orderUuid,
            orderTo: response.data.order_uuid,
          }
        }).then(response => {
          console.log(response);
          this.setIsFetching(false);
          if (response.code === 200) {
            this.props.history.push(`/${branchUrl}?cart=1`);
          }
         }).catch(error => {
            console.log(error);
         });
      }
     }).catch(error => {
        console.log(error);
     });


    // /?cart=1
  }
  setIsFetching = (param) => {
    this.setState({
      isFetching: param
    })
  }
  servingTime = (order) => {

  }
  closeModal = () => {
    this.setState({
      activeReadyPayModal: false
    });
  }
  renderPrice = (order_item, s) => {
    let totalModifiers = 0;
    if (order_item.modifiers && order_item.modifiers.length) {
      order_item.modifiers.forEach((modifier) => {
        if (modifier.price) totalModifiers += correctModifierSurcharges(parseFloat(modifier.price), s);
      })
    }
    const gross = correctModifierSurcharges(order_item.quantity * order_item.price, s) + totalModifiers * order_item.quantity;

    return gross.toFixed(2)
  }

  getSubtotal = (order) => {
    let gross = 0
    order.order_items.map((item, i) => {
      let totalModifiers = 0;
      if (item.modifiers && item.modifiers.length) {
        item.modifiers.forEach((modifier) => {
          if (modifier.price) totalModifiers += correctModifierSurcharges(parseFloat(modifier.price), order.surcharges);
        })
      }
      gross += correctModifierSurcharges(item.quantity * item.price, order.surcharges) + totalModifiers * item.quantity;
    })

    return gross
  }

  getDiscountAmount = (order) => {
    return this.getSubtotal(order) * (order.discount_value / 100)
  }

  renderModifiers = (modifiers) => {
    let modifiersString = "";
    if (modifiers && modifiers.length) {
      modifiers.forEach((modifier, i) => {
        modifiersString += `${modifier.modifier_set}: ${modifier.display_name}`
        // if (i !== (modifiers.length - 1)) {
        //   modifiersString += ", "
        // }
      })
    }
    return modifiersString
  }

  renderCartModifiersList = (modifiers) => {

    let modifiersRender = [];

    if (modifiers && modifiers.length) {
      modifiers.forEach((modifier) => {
        modifiersRender.push(<p>{modifier.modifier_set+ ': ' + modifier.display_name + ''}</p>);
      })
    }
    return modifiersRender
  }

  calculateSurchargesValue(surcharges, discount_value) {
    return surcharges - discount_value/100
  }

  calculateSurchargesAmount(surcharges = 1, surcharges_amount = 0, discount_value = 0, discount_amount = 0) {
    surcharges -= discount_value/100
    if (surcharges < 1) {
      return surcharges_amount + discount_amount
    } else if (surcharges > 1 ) {
      return Math.abs(Math.abs(surcharges_amount) - discount_amount)
    } else {
      return discount_amount
    }
  }

  render() {
    const {
      order,
      isFetching,
      branchName,
      branch,
      activeReadyPayModal
    } = this.state;

    const { order_type, order_type_display, is_delivered, surcharges, vat_amount, discount_value, surcharges_amount, discount_amount } = order;
  	const containerStyle = {
	    width: this.props.windowSize.windowWidth,
	    height: this.props.windowSize.windowHeight,
	    minHeight: this.props.windowSize.windowHeight
	  };
    const { role } = this.props;
    const { branchUrl, orderUuid } = this.props.match.params;

    const phone = _.get(branch, 'contacts.phone');
    const lat = _.get(branch, 'coordinates.lat');
    const lng = _.get(branch, 'coordinates.lng');
    return (
      <div className="singleOrder" style={containerStyle}>
      	{ !isFetching &&  <div className="checkoutPaid">
          <div className="checkoutPaidCard">
            <div className="menuTop" style={{ minHeight: '1px', paddingTop: '15px' }}>
              <div className="menuTop__name">{branchName}</div>

              {!is_delivered ? <div className="menuTop__name menuTop__name--prapare" style={{
                maxWidth: '100%',
                fontWeight: 500,
                fontSize: '18px',
                lineHeight: '20px'
              }}>Your order is now being prepared</div> : null }

            </div>
            { false ? <div className="checkoutPaidCard__orderId">Order #{order.index_id}</div> : null }
            <div className="checkoutPaidCardStatus" style={{ paddingTop: '20px' }}>
              <div>
                <div>Order #{order.index_id}</div>
                { order_type === 'dine_in' ? <div>{order_type_display}</div> : null }
                <div>{order_type === 'takeout' ? 'Collect order: ' : '' }{order.submitted_at}</div>
              </div>
              <div className="checkoutPaidCardStatus__right">{this.renderStatus()}</div>
            </div>
            <div className="checkoutPaidCard__list">
              {order.order_items.map((item, i) =>
                <div key={i} className="checkoutPaidCard__item">
                  <div className="checkoutPaidCard__itemFirst">
                    <div>{item.product.display_name}</div>
                    {item.comments && <div style={{color: '#868686', marginTop: '7px', fontSize: '12px'}}>{item.comments}</div>}
                    { item.modifiers && item.modifiers.length ? <div style={{color: '#868686', marginTop: '5px', fontSize: '12px'}}>
                      <div>{this.renderCartModifiersList(item.modifiers)}</div>
                    </div> : null }
                  </div>
                  <div className="checkoutPaidCard__itemMiddle">{item.quantity}</div>
                  {surcharges === 1 ?
                    <div className="checkoutPaidCard__itemLast">{this.renderPrice(item, 1)}</div>
                    :
                    <>
                      <div className="checkoutPaidCard__itemLast" style={{textDecoration:"line-through"}}>{this.renderPrice(item, 1)}</div>
                      <div className="checkoutPaidCard__itemLast">{this.renderPrice(item, surcharges)}</div>
                    </>
                  }
                </div>
              )}
            </div>
            <div className="checkoutPaidCard__footer">
              <div className="checkoutPaidCard__footerItem">
                GST
                <span className="checkoutPaidCard__footerPrice checkoutPaidCard__footerPrice--high">{!!vat_amount ? vat_amount.toFixed(2) : ''}</span>
              </div>
              {!!order ? order.surcharges != 1 ?
                <div className="checkoutPaidCard__footerItem">
                  { order.surcharges > 1 ? `Restaurant surcharges` : `Restaurant discount` }
                  <span className="checkoutPaidCard__footerPrice checkoutPaidCard__footerPrice--high">{surchargesToString(order.surcharges)} | ${ !!order.surcharges_amount ? Math.abs(order.surcharges_amount).toFixed(2):''}</span>
                </div>
                : null
              : null}
              {order.processing_fee != 0 ?
                <div className="checkoutPaidCard__footerItem">
                  Processing fee
                  <span className="checkoutPaidCard__footerPrice checkoutPaidCard__footerPrice--high">${!!order.processing_fee? order.processing_fee.toFixed(2):''}</span>
                </div>
                : null}
              { order.discount_value > 1 ?
                <>
                <div className="checkoutPaidCard__footerItem">
                  Subtotal
                  <span className="checkoutPaidCard__footerPrice checkoutPaidCard__footerPrice--high">${ this.getSubtotal(order).toFixed(2) }</span>
                </div>
                <div className="checkoutPaidCard__footerItem">
                  Discount
                  <span className="checkoutPaidCard__footerPrice checkoutPaidCard__footerPrice--high">{discount_value}% | ${ this.getDiscountAmount(order).toFixed(2) }</span>
                </div>
                </>
                : null }
              <div className="checkoutPaidCard__footerItem">
                Total
                <span className="checkoutPaidCard__footerPrice checkoutPaidCard__footerPrice--high">${this.calculateTotal(order.total, order.processing_fee)}{order?.transaction?.method_display === 'card' && order?.is_paid === 1 && order?.payment_processing_by_client === 1 && order.processing_fee != 0 ? `*` : null}</span>
              </div>
              { order?.transaction?.method_display === 'card' && order?.is_paid === 1 && order?.payment_processing_by_client === 1 && order.processing_fee != 0 ?
                <div className="checkoutPaidCard__footerItem">
                  <span className="checkoutPaidCard__footerPrice checkoutPaidCard__footerPrice--high">*The payment processing fee was applied</span>
                </div>
                : null}
            </div>
            { role === 'customer' ? <div className="checkoutPaidCard__footerLinks">
              An email receipt will be sent to your email
            </div> : null }
            <div className="singleOrder__footer">
              { order_type === 'dine_in' && !order.is_paid && !order.is_ready_to_pay  ? <div className="singleOrder__footerRow">
                <Button style={{display: "list-item"}} onClick={this.readyToPay}>
                  Ready to Leave & Pay?
                </Button>
              </div> : null }
              <div className="singleOrder__footerRow">
                <Button className="appBody__bottomBtn" style={{marginBottom: '10px', display: "list-item"}} onClick={this.reOrder}>
                  Reorder
                </Button>
                <Link
                  className="appBody__bottomBtn"
                  style={{display: 'block'}}
                  to={`/${branchUrl}/`}>New Order</Link>

                { order.is_paid ? <Link
                  className="appBody__bottomBtn"
                  style={{display: 'block', marginTop: '10px'}}
                  to={`/${branchUrl}/orders/${orderUuid}/receipt`}>Receipt</Link> : null }
              </div>
            </div>
            { order_type !== 'dine_in' ? <div className="checkoutPaidCard__footerLinks">
              {phone ? <div>Need help with anything? Call <a href={`tel:${phone}`}>{phone}</a></div> : null }
              {lat && lng ? <div>
                <a
                  href={"http://maps.google.com/maps?daddr="+(lat)+","+(lng)}
                  target="_blank"
                  className="UserController__btn"
                  rel="noopener noreferrer"
                  style={{
                    'width': 'auto'
                  }}
                  >
                  I need directions to the restaurant</a>
                </div> : null }
            </div> : null }
          </div>
         </div> }
         {isFetching && <div className="singleOrder__loading">Loading...</div>}
         <ReadyPay
          {...this.props}
          activeReadyPayModal={activeReadyPayModal}
          closeModal={this.closeModal}
          branchUrl={branchUrl}
        />
      </div>
    )
  }
}

export default withWindowSizeListener(SingleOrders);
