import React, { useEffect, useState } from 'react';
import { withWindowSizeListener } from 'react-window-size-listener';
import Settings from 'services/config/Settings';
import { parse, format, isValidNumber } from 'libphonenumber-js'
import Auth from 'services/utils/Auth';
import fetchJSON from 'services/utils/fetchJSON';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import Button from 'components/Button/Button';
import qs from 'qs';

import { ReactComponent as Logo } from 'components/images/dezy-header-logo-v2.svg';

const Login = (props) => {
  // Then inside the component body
  const { t } = useTranslation();
  const { branchUrl } = props.match.params;
  
  const [orderUuid, setOrderUuid] = useState(null);
  const [stage, setStage] = useState(1);
  const [timeLeft, setTimeLeft] = useState(null);

  const componentDidMount = () => {
    document.title = `${t('login.documentTitle', { defaultValue: 'Login' })} | ${Settings.title}`;

    if (window.location.search) {
      const param = window.location.search;
      const paramFromUrl = qs.parse(param, { ignoreQueryPrefix: true });
      const orderId = paramFromUrl.order ? paramFromUrl.order : null;
      setOrderUuid(orderId);
    } 
  }
  useEffect(componentDidMount, []);

  const preventDefault = (e) => {
    e.preventDefault();
  }
  // remove scrolling jumping feature on iphones
  useEffect(() => {
    document.body.addEventListener('touchmove', preventDefault, { passive: false });
    // returned function will be called on component unmount 
    return () => {
      document.body.removeEventListener('touchmove', preventDefault)
    }
  }, []);

  const containerStyle = {
    width: props.windowSize.windowWidth,
    height: props.windowSize.windowHeight,
    minHeight: props.windowSize.windowHeight
  };

  const initialValues = {
    phone: '',
    code: ''
  };
  const validate = values => {
    const errors = {};
    const requiredText = t('shareable.required', { defaultValue: 'Required' });

    if (stage === 1) {
      if (!values.phone) {
        errors.phone = requiredText;
      }
    }
    if (stage === 2) {
      if (!values.code) {
        errors.code = requiredText;
      }
    }

    return errors;
  };

  const proccessNumber = (value) => {
    console.log('phonePlain', value);

    const validNumberUS = isValidNumber(value, 'US');
    const validNumberCY = isValidNumber(value, 'CY');
    const validNumberAU = isValidNumber(value, 'AU');

    let parseNumber = parse(value, 'AU');
    let formatNumber = format(parseNumber.phone, 'AU', 'International_plaintext');

    if (!validNumberAU) {
      if (validNumberCY) {
        parseNumber = parse(value, 'CY');
        formatNumber = format(parseNumber.phone, 'CY', 'International_plaintext');
      } else if (validNumberUS) {
        parseNumber = parse(value, 'US');
        formatNumber = format(parseNumber.phone, 'US', 'International_plaintext');
      } else {
        formatNumber = value;
      }
    }
    console.log('formatNumber', formatNumber);
    return formatNumber;
  }

  const onSubmit = (values, { setSubmitting, resetForm, setErrors }) => {
    // alert(JSON.stringify(values, null, 2));

    // get user's timezone 
    values.stage = stage;
    values.phoneNumber = proccessNumber(values.phone);

    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/auth/login', {
      method: 'POST',
      body: values
    }).then(response => {
      // console.log(response);
      setSubmitting(false);

      if (response.code === 202) {
        if (response.data.stage) {
          setStage(response.data.stage);
          if (response.data.stage === 2) {
            setTimeLeft(60);
          }
        }
      }

      if (response.code === 200) {
        // If login was successful, set the token in cookie
        Auth.loginUser(response.token);
        if (orderUuid) {
          props.history.push(`/${branchUrl}/checkout/${orderUuid}`);
        } else {
          props.history.push(`/${branchUrl}`);
        }
        
      } else {
        let errors = {};
        if (response.field) {
          errors[response.field] = response.message;
        }
        if (response.errors) {
          errors = response.errors;
        }
        setErrors(errors);
      }
     }).catch(error => {
        console.log(error);
        setSubmitting(false);
     });
  };

  useEffect(() => {
    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {      
      if (timeLeft) {
        setTimeLeft(timeLeft - 1);
      }      
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      >
      {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, handleBlur, setSubmitting }) => {
        const errorField = (fieldKey) => {
          return errors[fieldKey] && touched[fieldKey] && <small className="fillInForm__itemError">{errors[fieldKey]}</small>
        }
        const formItemClass = (fieldKey = null) => {
          return `fillInForm__item ${(errors[fieldKey] && touched[fieldKey]) ? 'withError' : ''}`;
        }
        const sendCodeAgain = () => {
          setSubmitting(true);
          values.stage = 1;

          fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/auth/login', {
            method: 'POST',
            body: values
          }).then(response => {
            console.log(response);
            setSubmitting(false);
           }).catch(error => {
              console.log(error);
              setSubmitting(false);
           });
        }

        return (
          <form onSubmit={handleSubmit} className="app-container" style={containerStyle}>
            <div className="appBody__top">
              <div className="appBody__container">
                <div className="appBody__wrapper">
                  <div className="fillIn">
                    <div className="fillIn__logo">
                      <Logo alt="dezy logo" className="fillIn__logoImg" />
                      <div className="fillIn__pageTitle">
                        Log In
                      </div>
                    </div>
                    <div className="fillInForm" style={{
                      display: stage !== 1 && 'none' 
                    }}>

                      <div className={formItemClass('phone')}>
                        <label htmlFor="phone">{t('shareable.phoneNumber', { defaultValue: 'Phone number' })}</label>
                        <input
                          className="fillInForm__itemInput" 
                          type="tel"
                          name="phone"
                          placeholder={t('shareable.phoneNumber', { defaultValue: 'Phone number' })}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                        />
                        { errorField('phone') }
                      </div>

                    </div>
                    <div className="fillInForm" style={{
                      display: stage !== 2 && 'none' 
                    }}>
                      <div className={formItemClass('code')}>
                        <label htmlFor="code"><Trans i18nKey="shareable.code">Code</Trans></label>
                        <input
                          className="fillInForm__itemInput" 
                          type="text"
                          name="code"
                          placeholder={t('shareable.code', { defaultValue: 'Code' })}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.code}
                        />
                        { errorField('code') }
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="appBody__bottom">
              <div className="appBody__bottomContainer">
                { stage === 1 && <div style={{ paddingBottom: '30px' }}>
                  <Link className="fillIn__footerLink" to={`/${branchUrl}/signup${ orderUuid ? '?order=' + orderUuid : '' }`}>I don't have an account</Link>
                </div> }
                { stage === 2 && <div style={{ paddingBottom: '30px' }}>
                  <button
                    className="fillIn__footerLink"
                    type="button"
                    onClick={sendCodeAgain}
                    disabled={timeLeft !== 0}
                  >Send the code again {timeLeft ? `in ${timeLeft} sec` : ''}</button>
                </div> }

                <Button type="submit" disabled={isSubmitting}>
                  { stage === 1 && <Trans i18nKey="login.submitButton">Log In</Trans> }
                  { stage === 2 && <Trans i18nKey="shareable.submit">Submit</Trans> }
                </Button>
              </div>
            </div>
          </form>
        )
      }}
    </Formik>
  );
};


export default withWindowSizeListener(Login);

