import React from 'react';
import { Router, Switch, Route} from 'react-router-dom';

import App from './App';

import LogOut from 'screens/LogOut';
import Login from 'screens/Login/Login';
import CheckIn from 'screens/CheckIn/CheckIn';
import NoCheckIn from 'screens/CheckIn/NoCheckIn';

import SignUp from 'screens/SignUp/SignUp';
import AsGuest from 'screens/AsGuest/AsGuest';
import Payments from 'screens/Checkout/Payments/Payments';
import OrderType from 'screens/Checkout/OrderType/OrderType';

import Main from 'screens/Main/Main';
import Menu from 'screens/Menu/Menu';
import Help from 'screens/Help/Help';
import MyOrders from 'screens/Orders/MyOrders';
import SingleOrder from 'screens/Orders/SingleOrder';
import SingleOrderReceipt from 'screens/Orders/SingleOrderReceipt';

// utils for check authorization status checkAccessWrap
import { checkLogin, checkAccessWrap } from 'services/utils/requireAuth';
import AppWrapper from 'components/AppWrapper/AppWrapper';
import { createBrowserHistory as createHistory } from 'history'

export const history = createHistory();

const PrivateRoute = ({ component: Component, isAuthenticated, redirectTo, ...rest}) => (
	<Route
		{...rest}
		render = { props => (
			isAuthenticated(redirectTo, () => {
				return ( <Component {...props} /> );
			})
		)}
	/>
);

const WrapperRoute = ({ component: Component, ...rest}) => {
	return (
		<Route
			{...rest}
			render = { props => (
				<AppWrapper { ...props }>
					<Component {...props} />
				</AppWrapper>
			)}
		/>
	);
};
//
const router = () => (
	<Router history={history}>
		<App>
			<Switch>
				<Route exact path="/:branchUrl/checkin" component={checkAccessWrap(CheckIn, true, 'public')} />
				<Route exact path="/logout" component={LogOut} />
				<PrivateRoute exact path="/login" component={checkAccessWrap(Login, true, 'public')} isAuthenticated={checkLogin} redirectTo='/' />
				<PrivateRoute exact path="/:branchUrl/login" component={checkAccessWrap(Login, true, 'public')} isAuthenticated={checkLogin} redirectTo='/' />
				<PrivateRoute exact path="/:branchUrl/signup" component={checkAccessWrap(SignUp, true, 'public')} isAuthenticated={checkLogin} redirectTo='/' />
				<PrivateRoute exact path="/:branchUrl/continue-as-guest" component={checkAccessWrap(AsGuest, true, 'public')} isAuthenticated={checkLogin} redirectTo='/' />
				<Route exact path='/:branchUrl/checkout/:orderUuid' component={checkAccessWrap(OrderType, false, 'public')} />
				<Route exact path='/:branchUrl/checkout/:orderUuid/payment' component={checkAccessWrap(Payments, false, 'public')} />	
				<Route exact path='/:branchUrl/orders' component={checkAccessWrap(MyOrders)} />
				<Route exact path='/:branchUrl/orders/:orderUuid' component={checkAccessWrap(SingleOrder)} />	
				<Route exact path='/:branchUrl/orders/:orderUuid/receipt' component={checkAccessWrap(SingleOrderReceipt)} />	
				<Route exact path="/:branchUrl/logout" component={LogOut} />
				<WrapperRoute path='/:branchUrl/faq' component={Help} />
				<Route exact path="/i/:branchUrl/" component={checkAccessWrap(NoCheckIn, false, 'public')} />
				<Route exact path="/:branchUrl/" component={checkAccessWrap(Menu, false, 'public')} />
				<WrapperRoute path='/' component={Main} />
			</Switch>
		</App> 
	</Router>
);

export default router;
