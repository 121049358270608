import React from 'react';
import Settings from 'services/config/Settings';
import { withWindowSizeListener } from 'react-window-size-listener';
import fetchJSON from 'services/utils/fetchJSON';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import './styles.less'

class MyOrders extends React.Component {
  state = {
    orders: []
  }
  componentDidMount = () => {
    document.title = `My Orders | ${Settings.title}`;
    this.getOrders();

  }
  getOrders = () => {
    console.log('getOrders');
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v1/orders/user/getList', {
      method: 'GET'
    }).then(response => {
      console.log(response);
      if (response.code === 200 && response.data) {
        this.setState({
          orders: response.data
        });
      }
     }).catch(error => {
        console.log(error);
     });
  }
  renderStatus = (order) => {
    const render = [];

    if (!order.is_refunded && !order.is_canceled) {
      if (order.is_paid) {
        render.push(<div className="checkoutPaidCardStatus__tag paid" key="paid" style={{display: 'inline'}}>Paid</div>)
      } else {
        render.push(<div className="checkoutPaidCardStatus__tag notpaid" key="notpaid" style={{display: 'inline'}}>Not Paid</div>)
      }
    }

    if (order.is_refunded) {
      render.push(<div className="checkoutPaidCardStatus__tag notpaid" key="Refunded" style={{display: 'inline'}}>Refunded</div>)
    }

    if (order.is_canceled) {
      render.push(<div className="checkoutPaidCardStatus__tag notpaid" key="is_canceled" style={{display: 'inline'}}>Canceled</div>)
    }

    // if (order.is_delivered && order_type === 'dine_in') {
    //   render.push(<div className="checkoutPaidCardStatus__tag delivered" key="delivered">Delivered</div>)
    // }


    return render;
  }

  calculateTotal = (total, fee) => (total + fee).toFixed(2)
  renderOrders = () => {
    const {
      orders
    } = this.state;
    const render = [];
    const { branchUrl } = this.props.match.params;
    if (orders && Array.isArray(orders) && orders.length) {
      orders.forEach((order, i) => {
        const branchTitle = order.branch.title; //timezone
        const timezone = order.branch.timezone; //timezone
        const total = order.total;
        const date = moment.utc(order.updated_at).tz(timezone).format('DD MMM YYYY h:mm A');
        render.push(<li key={i} className="myOrdersItem">
          <Link className="myOrdersItem__link" to={`/${branchUrl}/orders/${order.uuid}`}>
            <div className="myOrdersItem__row">
              <div className="myOrdersItem__title">{branchTitle}</div>
              <div className="myOrdersItem__price">${this.calculateTotal(total, order.processing_fee)}</div>
            </div>
            <div className="myOrdersItem__secondRow">
              <div style={{paddingBottom: '10px'}}>
                { this.renderStatus(order) }
              </div>
              <div className="myOrdersItem__date">{date}</div>
            </div>
          </Link>
        </li>);
      });
    }
    return render;
  }
  render() {


    const containerStyle = {
      width: this.props.windowSize.windowWidth,
      height: this.props.windowSize.windowHeight,
      minHeight: this.props.windowSize.windowHeight
    };

    return (
      <div className="myOrders" style={containerStyle}>
        <div className="myOrders__title">My orders</div>
        <ul className="myOrders__list">
          { this.renderOrders() }
        </ul>
      </div>
    )
  }
};

export default withWindowSizeListener(MyOrders);
