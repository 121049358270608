import React from 'react';
import enhanceWithClickOutside from 'react-click-outside';

import './styles.less';
import { ReactComponent as CloseIcon } from 'components/images/close-icon.svg';


class DropdownMenu extends React.Component {

	handleClickOutside() {
		if (this.props.isOpen) {
			this.props.handleClose();
		}
	}
	render() {
		return (
			<div className={"slideDrawer" + (this.props.isOpen ? " opened" : " closed")} style={this.props.styles}>
				<div className="slideDrawer__close" onClick={this.props.handleClose}>
					<CloseIcon className="slideDrawer__icon" alt="close" />
				</div>
				{this.props.children}
			</div>
		)
	}
};


export default enhanceWithClickOutside(DropdownMenu);
