import React from 'react';
import Settings from 'services/config/Settings';
import { withWindowSizeListener } from 'react-window-size-listener';
import qs from 'qs';
import QRMainPage from 'components/images/qr-main-page.jpg';

import './styles.less';
//checkin

class Main extends React.Component {
	componentDidMount = () => {
		document.title = `Main | ${Settings.title}`;    
		 if (window.location.search) {
      const param = window.location.search;
      const paramFromUrl = qs.parse(param, { ignoreQueryPrefix: true });
      const branch = paramFromUrl.branch ? paramFromUrl.branch : null;
      const table = paramFromUrl.table ? paramFromUrl.table : null;
      
      if (branch) {
        this.props.history.push(`/checkin?branch=${branch}${ table ? '&table=' + table : '' }`);
      }
    }

  }

  render() {
  	const containerStyle = {
	    width: this.props.windowSize.windowWidth,
	    height: this.props.windowSize.windowHeight,
	    minHeight: this.props.windowSize.windowHeight
	  };

    return (
      <div className="mainPage" style={containerStyle}>
        <img src={QRMainPage} className="mainPage__qr" alt="qr phone" />
      	To identify Location please scan QR code
      </div>
    )
  }
};

export default withWindowSizeListener(Main);